.affix {
  top: 0px;
  width: 100%;
}

.anchor-offset {
  display: block;
  position: relative;
  top: -55px;
  visibility: hidden;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }
  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}


.no-gutter {
  & > [class*='col-'] {
    padding-right:0;
    padding-left:0;
  }
}

.views_infinite_scroll-ajax-loader{
  background: url("../images/spinner.gif") no-repeat 0 0;
  width: 40px; height: 40px;
  margin: 0 auto;
  display: block;
  img{
    display: none;
  }
}