.starter-template {
  padding: 40px 15px;
  text-align: center;
}

.description {
  font-family: $font;
  font-weight: 400;
  font-size: 20px;
  padding: 70px 75px;
  color: #4c4c4c;
  line-height: 1.5;
}

.description span {
  display: block;
  /*margin-top: 30px;*/
}

div.form-filter {
  width: 100%;
}

div.box-filter {
  width: 100%;
}

.filters label.homeview {
  color: #4c4c4c;
  float: left;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  &.label--inventory{
    margin-left: 37px;
  }
}

hr.filters {
  border-color: #4c4c4c;
  float: left;
  margin-bottom: 0;
  margin-top: 10px;
  padding-bottom: 0;
  width: 90%;
}

div.field-body p {
  margin-bottom: 30px;
}

a.arrowlink {
  text-decoration: none;
}

span.form-required {
  color: #4c4c4c;
}

div.clearfix {
  margin-bottom: 0;
  margin-top: 0;
}

div.clearfix label {
  margin-right: 0;
}

.page-homepage-items{
  #back-collection{
    display: none;
  }
  .menu-sec {
    background-color: #004077;
  }
}

.view-search-homepage .view-content.grid {
  position: relative;
}
