/*@font-face {
  font-family: 'euphemia_ucasregular';
  src: url('fonts/euphemiaucas-webfont.eot');
  src: local('☺'), url('fonts/euphemiaucas-webfont.woff') format('woff'),
  url('fonts/euphemiaucas-webfont.woff2') format('woff2'),
  url('fonts/euphemiaucas-webfont.ttf') format('truetype'),
  url('fonts/euphemiaucas-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'euphemia_ucasbold';
  src: url('fonts/euphemiaucas-bold-webfont.eot');
  src: local('☺'), url('fonts/euphemiaucas-bold-webfont.woff') format('woff'),
  url('fonts/euphemiaucas-bold-webfont.woff2') format('woff2'),
  url('fonts/euphemiaucas-bold-webfont.ttf') format('truetype'),
  url('fonts/euphemiaucas-bold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'euphemia_ucasitalic';
  src: url('fonts/euphemiaucas-italic-webfont.eot');
  src: url('fonts/euphemiaucas-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/euphemiaucas-italic-webfont.woff2') format('woff2'),
  url('fonts/euphemiaucas-italic-webfont.woff') format('woff'),
  url('fonts/euphemiaucas-italic-webfont.ttf') format('truetype'),
  url('fonts/euphemiaucas-italic-webfont.svg#euphemia_ucasitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?2aafhm');
  src: url('fonts/icomoon.eot?2aafhm#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?2aafhm') format('truetype'),
  url('fonts/icomoon.woff?2aafhm') format('woff'),
  url('fonts/icomoon.svg?2aafhm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}