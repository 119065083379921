// fonts

// unused
//$font-fallback : Arial, Helvetica, sans-serif;
/*$font-reg : "euphemia_ucasregular", $font-fallback;
$font-bold : "euphemia_ucasbold", $font-fallback;
$font-italic : "euphemia_ucasitalic", $font-fallback;*/

// new
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700');
$font : 'PT Sans', sans-serif;
$font-reg : 400;
$font-bold : 700;
$font-italic : "400i";

$icomoon : "icomoon";

// colors
$color-green-main : #c0e3a1;
$color-green-darker : #146b58;