.slick-slider {
  .slick-dots {
    text-align: center;
    padding: 0;
    margin-top: 0;
    margin-bottom: 40px;
    li {
      display: inline-block;
      button {
        display: block;
        border-radius: 100%;
        background: #e8e8e8;
        width: 20px;
        height: 20px;
        margin-left: 15px;
        border: none;
        &:focus,
        &:active {
          outline: none;
        }
      }
      &.slick-active {
        button {
          background: $color-green-darker;
        }
      }
    }
  }
}
.js-slider{
  display: none;
  &.slick-initialized{
    display: block;
  }
}