.dropdown-menu {
  min-width: 240px;
}

.icon-close {
  vertical-align: text-top;
}

.icon-menu {
  font-size: 29px;
  line-height: 22px;
  vertical-align: text-top;
  display: inline-block;
}

*::-ms-backdrop, .icon-menu {
  vertical-align: bottom;
}

/*custom dropdowns*/
.form-select {
  margin-bottom: 0;
  width: 100%;
}

.form-select .dk-selected {
  font-size: 14px;
  font-family: $font;
  font-weight: 700;
  padding: 12px 50px 12px 10px;
  line-height: 1.1;
  text-align: left;
  border: none;
  color: #4c4c4c;
  background: #ffffff;
  border-radius: 0;
}

.form-select .dk-selected:before {
  border-width: 0.4em 0.4em 0;
  border-top-color: #4c4c4c;
  margin: -0.2em 1em 0 0;
  z-index: 2;
}

.form-select .dk-selected:after {
  top: 0;
  right: 0;
  height: 100%;
  width: 38px;
  border-left: 1px solid #ffffff;
  background-color: #ffffff !important;
  margin: 0;
}

.form-select .dk-selected:hover:before,
.form-select .dk-selected:focus:before {
  border-top-color: #4c4c4c;
}

.form-select .dk-select-options {
  border: none;
  font-size: 14px;
  color: black;
  padding: 0;
  //border-bottom: 1px solid #4c4c4c;
  font-family: $font;
  font-weight: 700;
  max-height: 25em;
}

.form-select .dk-select-options .dk-option-highlight {
  background-color: #ffffff;
  color: $color-green-darker;
}

.form-select .dk-option-selected {
  background-color: #efe1bd;
  color: #4c4c4c;
}

.form-select .dk-option {
  line-height: 1;
  padding: 12px 10px;
  border-top: 1px solid $color-green-darker;
  color: $color-green-darker;
  background: #dbebcd;

}

.form-select.dk-select-open-down .dk-selected {
  border-radius: 0;
  background: $color-green-darker;
  color: #ffffff;
}
.form-select.dk-select-open-down .dk-selected{
  &:after{
    background: $color-green-darker !important;
  }
}

.form-select.dk-select-open-up .dk-selected {
  border-radius: 0;
}

.form-select.dk-select-open-up .dk-selected:before,
.form-select.dk-select-open-down .dk-selected:before {
  border-width: 0 0.4em 0.4em;
  border-bottom-color: #4c4c4c;
}

.form-select.dk-select-open-up .dk-select-options {
  border-radius: 0;
}

.form-select.dk-select-open-down .dk-select-options {
  border-radius: 0;
}

.form-select.error .dk-selected {
  color: #e5342b;
}

.form-select .dk-selected {
  color: #4c4c4c;
  background: #ffffff;
  border: 1px solid transparent;
}

.form-select .dk-selected:before {
  //border-top-color: #4c4c4c;
  content: "\e905";
  border: none;
  margin: 0;
  transform: translateY(-50%);
  right: 20px;
  font-family: "icomoon";

}

.form-select .dk-selected:after {
  border-left: none;
  background-color: #ffffff !important;
}

.form-select .dk-selected:hover:before,
.form-select .dk-selected:focus:before {
  //border-top-color: #4c4c4c;
  border: none;
}

.form-select.dk-select-open-up .dk-selected:before,
.form-select.dk-select-open-down .dk-selected:before {
  //border-bottom-color: #4c4c4c;
  border: none;
}

.dropdown .glyphicon-menu-down {
  font-size: 12px;
}

// empty dropdown
.form-item-sort-order {
  .form-select {
    &.dk-select-open-down{
      .dk-selected{
        color: #4c4c4c;
      }
    }
    .dk-selected{
      background: none;
      border: 1px solid #b5b5b5;
      &:after{
        background: none !important;
      }
    }

    .dk-select-options{
      .dk-option-highlight{
        background: #ffffff;
      }
    }

    .dk-option{
      background: #e8e8e8;
      color: #4c4c4c;
      border-top: 1px solid #b5b5b5;
    }
  }
}