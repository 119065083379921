/* Search Form */
.searchForm {
  padding-top: 30px;
  padding-bottom: 20px;
  overflow: hidden;
}

.searchAdvanceForm {
  padding-top: 30px;
  padding-bottom: 30px;
  display: none;
  .inventory{
    background: none;
    padding: 0;
    margin: 0;
  }

  .form-group{
    position: relative;
    label{
      position: absolute;
      left: -120px;
      top: 10px;
      color: $color-green-darker;
      font-weight: $font-bold;
    }
  }
  .textAdvance{
    span{
      color: $color-green-darker;
    }
  }
  .closeAdvance{
    p{
      color: $color-green-darker;
      font-weight: $font-bold;

      span{
        color: $color-green-darker;
      }
    }
  }

  .search--submit{
    .search-btn{
      margin-left: 0;
    }
  }
}



.search-form {
  margin-bottom: 0;
  background: $color-green-main;
}

.search-form .form-inline {
  margin-bottom: 10px;
}

.search-form .form-control {
  border: medium none;
  border-radius: 0;
  width: 500px;
  font-family: $font;
  font-weight: 400;
  font-size: 14px;
  height: 40px;

}

.search-btn {
  background: $color-green-darker;
  border: medium none;
  border-radius: 0;
  margin-left: 7px;
  width: 150px;
  height: 41px;
  margin-top: -1px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  @include hoverCta($color-green-darker);
  img{
    width: 22px; height: 22px;
  }
}

.advanceSearch {
  color: $color-green-darker;
  font-family: $font;
  font-weight: 400;
  margin-top: 22px;
}

.advanceSearch span {
  font-size: 14px;
}

.advanceSearch .advanceSearchText {
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.search-form label {
  font-family: $font;
  font-weight: 400;
  color: #4c4c4c;
  font-size: 14px;
  width: 120px;
  text-align: left;
}

.textAdvance {
  margin-bottom: 30px;
}

.textAdvance span {
  font-size: 25px;
  font-family: $font;
  font-weight: 400;
  color: #4c4c4c;

}

.closeAdvance {
  margin-bottom: -44px;
  margin-top: 5px;
  cursor: pointer;
  padding-right: 12px;  
}

.closeAdvance span {
  font-size: 17px;
  //vertical-align: sub; /* IE6, IE7, IE8, IE9 */
  vertical-align: calc(-2px);
  font-family: $font;
  font-weight: 400;
  color: #4c4c4c;
}

*::-ms-backdrop, .closeAdvance span {
  vertical-align: sub;
}


/* header search */
#header-search {
  z-index: 3;
}

#header-search.affix {
  margin-top: 47px;
}

#header-search-toggle {
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  padding: 24px 30px;
  top:0;
  right: 120px;
  text-indent: -9999em;
  background: none;
  z-index: 5;

  &:after{
    content: "";
    background: url('../images/loupe.svg') no-repeat center;
    width: 18px; height: 18px;
    display: block;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }
}

#header-search-toggle.open {
  //background: $color-green-main url('../images/loupe.svg') no-repeat 0 0;
  background: $color-green-main;
}

#header-search-toggle.below {
  z-index: 0;
}

#header-search .container {
  position: relative;
}

#header-search .search-form {
  overflow: hidden;
  display: none;
}

#header-search .search-form.open {
  height: auto;
}

.search-results-title h2 {
  margin: 50px;
  font-family: $font;
  font-weight: 700;
  color: #4c4c4c;
  font-size: 28px;
}

.container.search-results-form {
  margin-bottom: 8px;
}

.search-results-form .search-form {
  margin-right: 1px;
  overflow: hidden;
  display: none;
}

.search-results-form .container {
  width: 100%;
}

.search-results-form .modif-button-container {
  background-color: $color-green-main;
  padding: 18px;
}

.search-results-form .modif-button-container .buttons {
  background-color: $color-green-darker;
  color: #ffffff;
  @include hoverCta($color-green-darker);
}

.search-results-form-header {
  padding: 15px 20px;
  font-size: 18px;
  font-family: $font;
  font-weight: 700;
  color: #ffffff;
  background-color: $color-green-darker;
  margin-right: 1px;
}