@media (min-width: 1200px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
    width: 1030px;
  }
}

@media (max-width: 1199px) {

  .object img {
    width: 229px;
  }

  .object .title {
    width: 229px;
  }

  .masonry-processed {
    max-width: 940px;
  }

  .grid-item {
    width: 229px;
  }

  .inventory {
    margin-right: 2px;
  }

  .object .title div {
    font-size: 11px;
  }
}

@media (min-width: 991px) {
  .blocks{
    .content-block{
      &:first-child{
        padding-bottom: 55px;
      }
    }
  }

  .searchf{
    .form-group{
      width: 500px;
    }
  }

  .search-form .form-inline {
    width: 670px;
    margin: 0 auto 10px;
  }

}

@media (max-width: 991px) {
  .form-inline .form-control {
    width: 440px;
  }

  input[type='text'], input[type='email'], input[type='search'],
  input[type='password'], input[type='tel'], textarea {
    width: 400px;
  }

  .form-section label.file-input-label {
    width: 100px;
  }

  .form-section .buttons.add-file-input {
    margin-left: 100px;
  }

  .form-section .annexe_attachment {
    width: 400px;
  }

  .form-section .file-input-text {
    display: block;
    float: left;
    width: 204px;
    padding: 7px 20px;
    font-size: 14px;
    font-family: $font;
    font-weight: 400;
  }

  /*    .inventory .dropdown a {
          font-size: 13px;
          padding-left: 10px;
          padding-right: 10px;
      }

      .inventory .dropdown.dropdown1,
      .inventory .dropdown.dropdown1 .dropdown-menu {
          padding-left: 10px;
          padding-right: 30px;
      }
      .inventory .dropdown2,
      .inventory .dropdown.dropdown2 .dropdown-menu {
         margin-left: -23px;
      }
      .inventory .dropdown.dropdown2 .dropdown-menu {
          margin-left: 0;
      }
      .inventory .dropdown3,
      .inventory .dropdown.dropdown3 .dropdown-menu {
          margin-left: -1px;
          padding-left: 0;
          padding-right: 32px;
      }
      .inventory .dropdown.dropdown3 .dropdown-menu {
          margin-left: 0px;
      }
      .inventory .dropdown.dropdown4,
      .inventory .dropdown.dropdown4 .dropdown-menu {
          margin-left: -40px;
          padding-left: 15px;
          padding-right: 6px;
      }
      .inventory .dropdown.dropdown4 .dropdown-menu {
          margin-left: 0px;
      }*/
  .object img {
    width: 100%;
  }

  .object .title {
    width: 100%;
  }

  .masonry-processed {
    max-width: 720px;
  }

  .grid-item {
    width: 234px;
  }

  .footerPatrimoine .visit-site {
    font-size: 13px;
  }

  .topImage {
    width: 100%;
  }
}

@media (max-width: 767px) {

  .az-element.az-image {
    text-align: center;
  }

  .az-element.az-image figcaption {
    text-align: center !important;
  }

  .az-element.az-text {
    margin-top: 60px;

    li {
      text-align: left;
    }
  }



  .az-element.az-row:first-child .az-column:first-child {
    .az-element.az-text {
      margin-top: 0;
    }
  }

  .az-element.az-separator {
    margin: 40px 0; 
  }

  .masonry-processed {
    max-width: 767px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .masonry-processed {
    margin: 0 -20px;
  }

  .navbar-toggle {
    visibility: hidden;
  }

  .navbar-toggle.collapsed {
    visibility: visible;
  }

  .closeMenu {
    margin-top: 10px;
    text-align: right;
    margin-right: 0;
  }

  .closeMenu .icon-close {
    font-size: 18px;
    cursor: pointer;
    color: #ffffff;
  }

  .navbar {
    min-height: 37px;
  }

  .navbar-inverse {
    z-index: 2;
  }

  .navbar-inverse .navbar-header {
    margin-top: 0;
  }

  .navbar-brand {
    width: 100%;
    height: 30px;
  }

  .navbar-brand > img {
    margin: 2px auto;
    width: 121px;
    height: auto;
  }

  #navbar {
    border: none;
    box-shadow: none;
    overflow: hidden;
  }

  #navbar .logo img {
    margin: 8px auto 10px;
    width: 121px;
  }

  #navbar .logo-FRB {
    margin: -10px auto 25px;
    width: 110px;
  }

  #navbar .logo-FRB img {
    height: 14px;
    float: left;
  }

  .navbar-nav {
    margin-bottom: 4px;
  }

  .navbar-inverse .navbar-nav li > a,
  .navbar-inverse .navbar-nav li.active > a {
    text-align: center;
    background-color: #646464;
    margin: 0 15px 10px;
    font-family: $font;
    font-weight: 700;
    font-size: 12px;
  }

  .navbar-inverse .navbar-nav li:first-child > a {
    padding: 8px 15px;
  }

  .navbar-inverse .navbar-nav li > a:hover,
  .navbar-inverse .navbar-nav li.active > a:hover {
    background-color: #FD8F3F;
  }

  .navbar-inverse a.navbar-brand{
    padding-top: 7px;
  }

  .navbar-toggle {
    margin-top: -29px;
    border: none;
    padding: 7px 0 0;
  }

  .navbar-toggle span {
    border: medium none;
    border-radius: 30px;
    display: block;
    font-size: 30px;
    height: 20px;
    line-height: 20px;
    width: 20px;
    font-family: $font;
    font-weight: 400;
    background-color: transparent;
    color: #ffffff;
  }

  .navbar-inverse .navbar-toggle:focus,
  .navbar-inverse .navbar-toggle:hover {
    background-color: transparent;
  }

  .navbar-inverse .navbar-toggle:hover span {
    background-color: #919090;
  }

  .menu .dropdown {
    padding: 10px 6px 10px;
  }

  .menu .dropdown.open,
  .menu .dropdown:hover {
    background-color: transparent;
  }

  .menu-sec {
    padding: 0;
    width: 100%;
  }

  .menu{
    .dropdown-toggle{
      p{
        font-size: 14px;
        span{
          margin-left: 0;
        }
      }
    }
  }

  

  .cloaseDropdown {
    background-color: #73726c;
    color: #ffffff;
    height: 40px;
  }

  #header-search.affix {
    margin-top: 39px;
  }

  #header-search-toggle {
    text-align: left;
    padding: 20px 30px;
    right: 90px;
    left: auto;
  }

  body{
    &:not(.page-homepage-items){
      #back-collection {
        margin-left: 20px;
      }
    }
  }

  #back-collection {
    margin-left: 30px;
    padding: 10px 0;
  }


  #back-collection .text {
    display: none;
  }

  .menu-sec .container {
    padding: 0;
  }

  .menu .dropdown.open a {
    font-size: 13px;
  }

  .menu .dropdown {
    &:hover,
    &:focus{
      .menuOpen{
        color: white;
      }
    }
  }

  .dropdown-menu > li > a,
  .dropdown-menu > li.dropdown-submenu {
    font-size: 14px;
    padding: 8px 50px 8px 20px;
  }


  .dropdown-menu > li > span {
    margin-top: -27px;
    font-size: 14px;
    margin-right: 15px;
  }

  .dropdown-menu .dropdown-submenu span {
    font-size: 14px;
    margin-right: -34px;
  }

  .dropdown-submenu .dropdown-menu > li > span {
    margin-top: -25px;
    font-size: 10px;
    margin-left: 19px;
  }

  .menu-sec .menu {
    width: 100%;
    text-align: right;
    margin-right: 0;
  }

  .menuClose {
    display: none;
    text-align: left;
    color: #ffffff;
    padding-left: 10px;
    font-size: 12px;
    font-family: $font;
    font-weight: 700;
  }

  .icon-menu {
    font-size: 26px;
    line-height: 19px;
  }

  .menuOpen {
    font-size: 12px;
    padding-right: 12px;
  }

  .menu-sec .dropdown-menu {
    width: 100%;

  }

  .menu-sec .menu .dropdown.open {
    background-color: #004077;
    color: white;
  }

  .dropdown-menu > li:first-child > a {
    border-top: 1px solid #4c4c4c;
  }

  h1 {
    font-size: 37px;
    margin-top: 30px;
  }

  .bg-top{
    min-height: 500px;
  }

  .bg-top h3 {
    font-size: 15px;
    padding: 0 40px;
  }

  .topImageDiv {
    margin-left: -35px;
    margin-right: -35px;
    overflow: hidden;
  }

  .topImage {
    margin-bottom: 60px;
    margin-top: 15px;
    margin-left: -10%;
    width: 120%;
  }

  .arrow {
    display: none;
  }

  .searchForm {
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .searchForm .form-inline .form-control {
    width: 100%;
  }

  .searchForm .search-btn {
    width: 100%;
    margin-left: 0;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .searchAdvanceForm .form-group {
    display: block;
    vertical-align: middle;
    margin-bottom: 0;
  }

  .searchAdvanceForm .form-inline .form-control {
    width: 375px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
  }

  .searchAdvanceForm .search-btn {
    width: 55px;
    margin-left: -7px;
  }

  .searchAdvanceForm label {
    display: none;
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    margin-left: 13px;
  }

  .closeAdvance {
    visibility: hidden;
    margin-bottom: -38px;
  }

  .closeAdvance span {
    visibility: initial;
    font-size: 20px;
    margin-right: -5px;
  }

  .textAdvance {
    margin-bottom: 25px;
  }

  .textAdvance span {
    font-size: 23px
  }

  .advanceSearch {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .advanceSearch .advanceSearchText {
    font-size: 18px;
    font-weight: normal;
  }

  .description {
    font-size: 15px;
    padding: 50px 0;
  }

  .more-info {
    .row {
      margin-top: 0;
      padding-bottom: 0;
      border: none;
    }
    .info {
      width: 100%; 
      .inner {
        padding: 0;
      }
      h3 {
        margin: 0;
        padding: 30px 0;
        border-top: 1px solid #d2d2d2;
        line-height: 1;
        position: relative;
        a {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: auto;
          margin-top: 0;
          span {
            color: $color-green-darker;
            margin-right: 0;
          }
        }
      }
      &:last-child {
        .inner {
          //padding-right: 20px;

          h3 {
            border-bottom: 1px solid #d2d2d2;
          }
        }
      }
    }
  }

  .more-info h3 {
    color: #4c4c4c !important;
    font-size: 16px;
    line-height: 30px;
    margin: 0 -20px 0 -38px;
    padding: 25px 18px;
  }

  .more-info p {
    display: none !important;
  }

  .more-info .min-plus {
    display: none;
  }

  .more-info h3 a {
    display: block;
    font-size: 20px;
    height: 77px;
    margin-top: -30px;
    position: absolute;
    text-align: right;
    text-decoration: none;
    width: 100%;
  }

  .more-info h3 a span {
    margin-right: -8px;
  }

  .more-info .info.info1,
  .more-info .info.info2,
  .more-info .info.info3 {
    padding-left: 0;
    padding-right: 0;
  }

  .filters--head {
    padding: 45px 0;
    h3 {
      font-size: 30px;
      text-align: center;
    }
  }

  .share {
    text-align: center !important;
    margin-top: 50px;

    & > span {
      display: block;
      font-size: 20px;
      color: #4c4c4c;
      margin-bottom: 20px;
    }
  }
  .social-share {
    width: 42px !important;
    height: 42px !important;
    svg {
      width: 42px !important;
      height: 42px !important;
    }
  }
 
  .plus span {
    margin-bottom: 32px;
  }

  .inventory {
    position: relative;
    overflow: hidden;
    margin: 0 -20px 10px;
    /*padding: 0 20px;*/
  }

  .inventory .dropdown1,
  .inventory .dropdown2,
  .inventory .dropdown3,
  .inventory .dropdown4 {
    padding: 0;
  }

  .inventory{
    .dropdown{
      width: 100%;
      padding: 0 0 1px 0;
    }
  }

  .filters-container {
    position: relative;
    display: none;
  }

  .inventory h3 {
    padding: 45px 0 40px;
    margin: 0;
    text-align: center;
  }

  .inventory .share {
    text-align: center;
    margin-top: 0;
    margin-bottom: 35px;
  }

  .inventory .share span {
    display: block;
    padding-bottom: 10px;
  }

  .filtersButton,
  .hideFiltersButton {
    background-color: #333;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-family: $font;
    font-weight: 700;
    font-size: 18px;
    height: auto;
    /*margin: 0 10px 15px;*/
    padding: 10px 0;
    position: relative;
    text-align: center;
    @include hoverCta(#333);
  }

  .hideFiltersButton {
    margin: 0;
    padding: 10px 20px;
    text-align: left;
    span {
      font-size: 15px;
    }
  }

  .inventory .filters-xs li.dropdown-submenu,
  .inventory .filters-xs .dropdown-menu > li.dropdown-submenu:focus,
  .inventory .filters-xs .dropdown-menu > li.dropdown-submenu:hover {
    background-color: #ffffff;
    padding: 0;
    color: #4c4c4c;
  }

  .inventory .filters-xs li.dropdown-submenu p {
    padding: 3px 50px 3px 20px;
    margin: 0;
  }

  .inventory .filters-xs li.dropdown-submenu span {
    color: #4c4c4c;
    margin-top: -4px;
  }

  .inventory .filters-xs li.dropdown-submenu.first,
  .inventory .filters-xs li.dropdown-submenu.first:focus,
  .inventory .filters-xs li.dropdown-submenu.first:hover {
    background-color: #4c4c4c;
    color: #ffffff;
  }

  .inventory .filters-xs li.dropdown-submenu.first span {
    color: #ffffff;
  }

  .inventory .filters-xs .dropdown-submenu .dropdown-menu > li:first-child > a {
    box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 1);
  }

  .noBorder {
    border: none !important;
  }

  .filters-xs .retour {
    background-color: #73726c;
    height: 37px;
    color: #ffffff;
    font-size: 12px;
    padding-top: 8px;
  }

  .filters-xs .retour span {
    color: #ffffff;
    float: left;
    margin-right: 15px;
    margin-top: -12px;
    padding-left: 15px;
  }

  .filters-xs .dropdown.open {
    margin: 0 -20px;
  }

  .filters-xs .dropdown.open .filtersButton {
    display: none;
  }

  .filters-xs .dropdown-menu {
    position: relative;
    margin-top: 0;
    background-color: #e2d1a6;
  }

  .filters-xs .dropdown > .dropdown-menu {

    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .filters-xs .dropdown-submenu.open > .dropdown-menu > li > a {
    background-color: #ffffff;
    font-size: 11px;
    padding: 0 40px;
    border-bottom: 1px solid #4c4c4c;
    border-top: none;
  }

  .subsection {
    background-color: #e8e8e8;
    color: #4c4c4c;
    font-size: 30px;
    height: 115px;
    padding-top: 35px;
    text-align: center;
    clear: both;
  }

  .masonry-processed:after {
    content: '';
    display: block;
    clear: both;
  }
  .grid-sizer {
    width: 32.5%;
  }

  .grid-item {
    width: 32.5%;
  }

  .gutter-sizer {
    width: 1.25%;
  }

  .object .title div {
    font-size: 9px;
  }

  .contact .contacts-list li {
    display: inline-block;
    width: 45%;
    vertical-align: top;
    margin-top: 30px;
    padding: 0 20px;
  }

  .form-section {
    padding: 30px 0;
  }

  .field-block {
    width: 100%;
  }

  input[type='text'], input[type='email'], input[type='search'],
  input[type='password'], input[type='tel'], textarea {
    width: 100%;
  }

  .form-section h2.top-space {
    margin-top: 30px;
  }

  .form-section label {
    width: 100%;
    text-align: left;
  }

  .form-section .button-container {
    text-align: center;
  }

  .form-section .buttons.submit {
    margin-left: 0;
    margin-top: 20px;
  }

  .form-section .tabs {
    margin-bottom: 30px;
  }

  .form-section .tabs .tab {
    width: 100%;
    border-left: none;
    margin-bottom: 1px;
  }

  /* search page */
  .search-results-title h2 {
    font-size: 20px;
  }

  .container.search-results-form {
    padding-left: 0;
    padding-right: 0;
  }

  .bg-top-arrow{
    margin: 0;
    .arrow{
      display: block;
    }
  }

  footer .plus span {
    font-size: 33px;
    height: 40px;
    line-height: 32px;
    width: 40px;
    margin-top: 20px;
  }

  footer .closeFooter {
    margin-top: 15px;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  footer .orderDiv {
    margin: 0 -15px;
  }

  .footerPatrimoine .closeFooter {
    margin-top: -12px;
  }

  footer .closeFooter .icon-close {
    font-size: 17px;
  }

  footer .publication {
    font-size: 15px;
    text-align: center;
    padding-top: 25px;
  }

  footer .title {
    font-size: 20px;
    text-align: center;
    padding: 10px 20px;
  }

  footer .description {
    font-size: 13px;
    text-align: center;
    padding: 2px 0 10px;
  }

  footer .order {
    font-size: 12px;
    margin-top: 12px;
    text-align: center;
    width: 100%;
    margin-bottom: 33px;
  }

  footer .img-publication {
    margin: auto;
    padding-bottom: 15px;
    padding-top: 25px;
    width: 210px;
  }

  footer .footer-row {
    margin: 0 -15px;
  }

  .footerPatrimoine .logo {
    margin: 25px 0 10px;
    float: none;
  }

  footer .logo img {
    margin: auto;
    width: 125px;
  }

  footer .footerOff {
    display: block;
  }
  
  .footerPatrimoine .title {
    font-size: 20px;
    padding: 10px 20px;
  }

  .footerPatrimoine .description {
    font-size: 16px;
  }

  .footerPatrimoine .visit-site {
    font-size: 14px;
    padding: 8px 15px;
    text-align: center;
    margin: 25px auto;
    width: 100%;
  }

  .footerPatrimoine .list {
    font-size: 16px;
    padding: 15px 0 10px;
    text-align: center;
  }

  .footerPatrimoine .list > p {
    padding-left: 10px;
    text-indent: -10px;
    margin-bottom: 15px;
  }

  .footerPatrimoine .logo-FRB {
    display: block;
    margin: 7px auto 45px;
    width: 235px;
  }

  .footerPatrimoine .logo-FRB img {
    height: 60px;
    float: left;
  }

  .footerPatrimoine .plus span {
    background-color: #a6a6a6;
    color: #4c4c4c;
  }

  .footerPatrimoine .plus span:hover {
    background-color: #919090;
  }

  .footerPatrimoine .footerOff .icon-close {
    color: #ffffff;
  }

  .footerPatrimoine {
    .logo{
      &.logo--patrimoine{
        margin-right: 0;
        img{
          height: 40px;
          width: auto !important;
        }
      }
    }
    .logo-FRB {
      margin-bottom: 25px;
      text-align: center;
      width: auto;
      margin-top: 3px;
      margin-right: 10px;
      img {
        float: none;
      }
    }
  }

  .footer {
    margin-bottom: 20px;
    margin-top: 18px;
    font-size: 14px;
    .dash {
      display: none;
    }
    span {
      margin-bottom: 15px;
    }
    a {
      font-weight: bold;
    }
    .footerMostwanted {
      margin-top: 30px;
      a {
        font-weight: normal;
      }
      .mw {
        display: inline-block;
        padding: 0;
      }
    }
  }

  .footer span {
    display: block;
    padding: 0 20px;
    text-align: center;
  }

  

  .mobile-text-center {
    text-align: center !important;
  }

  // change order of details column => image always first
  .az-ctnr {
    .az-row {
      display: flex;
      flex-direction: column;
      &:nth-child(4n+3) {
        .az-column {
          &:last-child {
            order: 1;
          }
          &:first-child {
            order: 2;
          }
        }
      }
    }
  }

  section:not(.block-system) input[type='text'],
  section:not(.block-system) input[type='email'],
  section:not(.block-system) input[type='search'],
  section:not(.block-system) input[type='password'],
  section:not(.block-system) input[type='tel'],
  section:not(.block-system) textarea {
    width: 100%;
  }

  .page-locations {
    footer {
      margin-top: 0;
    }
  }

  .form-select{
    .dk-select-options{
      position: relative;
    }
  }

}

@media (max-width: 480px) {

  .az-column{
    padding-left: 0;
    padding-right: 0;
  }

  .az-element.az-separator {
    margin: 20px 0; 
  }

  .region-secondary-header {
    height: 38px;
  }

  .searchAdvanceForm .form-inline .form-control {
    width: 100%;
  }

  .c-block {
    display: none;
  }

  .figure figcaption {
    padding-left: 10px;
    padding-right: 10px;
  }

  .container.blocks {
    padding: 0;
  }

  .container.smaller {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-block {
    /*padding-bottom: 30px;*/
    .az-text p {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .content-block h2 {
    position: relative;
    text-align: left;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    padding: 30px 75px 30px 20px;
  }

  .content-block h2:after {
    display: block;
    position: absolute;
    content: "\e901";
    top: 50%;
    margin-top: -11px;
    right: 25px;
    color: $color-green-darker;
    font-size: 20px;
    font-family: $icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .content-block h3 {
    text-align: center;
  }

  .content-block p, .content-block ul, .content-block table {
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .content-block.left-image .figure,
  .content-block.right-image .figure {
    width: 100%;
  }

  .content-block.left-image .text-content,
  .content-block.right-image .text-content {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .content-block.full-width-image .text-content,
  .content-block.no-image-centered .text-content {
    margin: 20px 0;
  }

  .content-block.where-to-find {
    padding-top: 0;
  }

  .content-block.where-to-find .figure {
    position: relative;
    left: 0;
    top: auto;
    width: 100%;
    float: none;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .content-block.where-to-find .text-content {
    margin-left: 0;
    max-width: 100%;
    float: none;
  }

  .content-block.where-to-find .text-content h2 {
    margin: 0;
  }

  .content-block.where-to-find .text-content .c-block {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .bg-top-simple {
    padding-left: 0;
    padding-right: 0;
  }

  .bg-top-simple .title-top h1 {
    font-size: 27px;
  }

  .article-details-header {
    padding: 20px;
  }

  .article-details-header .pull-right {
    display: block;
    text-align: center;
    float: none !important;
  }

  .article-details .pictures .figure {
    width: 100%;
  }

  .article-details .pictures .figure:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .article-details .pictures .figure img {
    width: 100%;
    height: auto;
  }

  .article-details .title-spec {
    margin-bottom: 20px;
  }

  .article-details .specs,
  .article-details .desc {
    padding: 30px 0;
  }

  .article-details .desc {
    //padding-top: 0;
  }

  .article-details .specs p,
  .article-details .desc p {
    font-size: 15px;
  }

  .article-details .specs .col-sm-6 {
    padding-right: 0;
  }

  .article-details .specs .r_title {
    display: inline;
    float: none;
    width: auto;
  }

  .article-details .specs .r_spec {
    display: inline;
    float: none;
    width: auto;
  }

  .grid-sizer {
    width: 49%;
  }

  .grid-item {
    width: 49%;
  }

  .gutter-sizer {
    width: 1%;
  }

  /* contact page */
  .contact {
    margin-bottom: 30px;
  }

  .contact .logo-frb {
    display: block;
    max-width: 340px;
    width: 100%;
    height: 137px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 30px auto 0;
    text-indent: -9999em;
  }

  .contact h2 {
    margin-bottom: 0;
    font-size: 21px;
  }

  .contact .contacts-list li {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
    padding: 0 20px;
  }

  

  .bg-top{
    min-height: 470px;
  }

  

  .form-item{
    position: relative !important;
    bottom: auto;
    &.form-item-sort-order{
      margin-top: 20px;
      padding-bottom: 35px;

      .form-select{
        .dk-selected{
          border: none;
          border-top: 1px solid #b5b5b5;
          border-bottom: 1px solid #b5b5b5;
        }
      }
    }
    .form-select{
      .dk-selected{
        border-right: none;
        border-left: none;
        padding-left: 20px;
      }
    }
  }

  .form-select{
    .dk-option{
      padding-left: 20px;
    }
    .dk-selected{
      padding-left: 20px;
    }
  }

  .subsection{
    height: auto;
    padding: 20px 0;
    &.subsection--inventory{
      padding-bottom: 5px;
    }
  }

  .inventory{
    padding: 0;
  }

  .filtersButton{
    margin-top: 15px;
  }

}

@media only screen and (max-width: 880px) {
  .bg-top{
    background-position: 50% 60%;
    background-size: 200%;
    padding-top: 15px;
  }

  .slick-slider{
    .slick-dots{
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .image-modal-content {
    width: 100%;
  }
}