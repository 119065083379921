body {
  font-family: $font;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.container.smaller {
  padding-left: 70px;
  padding-right: 70px;
}

h1 {
  color: #fff;
  font-family: $font;
  font-weight: 400;
  font-size: 60px;
  position: relative;
}