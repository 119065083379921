.contact {
  margin-bottom: 60px;
}

.contact .logo-frb {
  display: block;
  width: 340px;
  height: 137px;
  background-image: url('../images/logo-FRB-gray.gif');
  margin: 60px auto 35px;
  text-indent: -9999em;
}

.contact .buttons.email {
  margin: 30px auto;
}

.contact .address {
  font-size: 20px;
  color: #4c4c4c;
  line-height: 1.8;
}

.contact h2 {
  margin-bottom: 30px;
  color: #4c4c4c;
  font-size: 25px;
  font-family: $font;
  font-weight: 700;
}

.contact .contacts-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact .contacts-list li {
  display: inline-block;
  width: 32%;
  vertical-align: top;
  margin-top: 75px;
  padding: 0 30px;
}

.contact .contacts-list h3 {
  color: #fd8f3f;
  font-family: $font;
  font-weight: 700;
  font-size: 20px;
}

.contact .contacts-list h4 {
  color: #4c4c4c;
  font-family: $font;
  font-weight: 700;
  font-size: 14px;
}

.contact a.email-link,
.contact a.phone {
  display: block;
  color: #4c4c4c;

}

.contact a.email-link:before {
  display: inline-block;
  content: '';
  margin-right: 10px;
  width: 18px;
  height: 12px;
  background-image: url('images/ico-mail-gray.png');
}

.contact a.phone:before {
  display: inline-block;
  content: '';
  margin-right: 10px;
  width: 17px;
  height: 17px;
  background-image: url('images/ico-phone.png');
}

.form-section {
  margin-bottom: -60px;
  padding: 65px 0;
}

.form-section.contact-form {
  background-color: #d4d4d4;
}

.form-section h2 {
  margin-top: 0;
  margin-bottom: 50px;
  text-align: center;
  font-size: 25px;
  color: #4c4c4c;
  font-family: $font;
  font-weight: 700;
}

.form-section h2.top-space {
  margin-top: 50px;
}

.form-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  color: #4c4c4c;
}

.form-section label {
  width: 33.3%;
  margin-top: 10px;
  text-align: right;
  float: left;
}

.form-section label.upper {
  margin-top: 0;
}

.form-section label.file-input-label {
  width: 190px;
}

.form-section .buttons.submit {
  margin-left: 33.3%;
  margin-top: 55px;
}

.form-section .annexe_attachment {
  text-align: left;
  border: 1px solid $color-green-main;
  padding: 2px;
  width: 500px;
  margin: 0 5px 0 0;
}

.form-section .file-input-block {
  margin-bottom: 10px;
}

.form-section .file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form-section .file-input-button {
  display: block;
  float: left;
  padding: 7px;
  width: 190px;
  text-align: center;
  font-size: 14px;
  color: $color-green-darker;
  background-color: $color-green-main;
  font-family: $font;
  font-weight: 400;
}

.form-section .file-input-text {
  display: block;
  float: left;
  width: 304px;
  padding: 7px 20px;
  font-size: 14px;
  font-family: $font;
  font-weight: 400;
}

.form-section .buttons.upload {
  min-width: 190px;
}

.form-section .buttons.add-file-input {
  margin-left: 190px;
  height: 40px;
  font-size: 31px;
  line-height: 0;
}

.form-section .tabs {
  margin-bottom: 70px;
}

.form-section .tabs .tab {
  padding: 10px;
  margin: 0;
  text-align: center;
  background-color: #a6a6a6;
  border-left: 1px solid #ffffff;
  cursor: pointer;
}

.form-section .tabs .tab:first-child {
  border-left: none;
}

.form-section .tabs .tab .state {
  position: absolute;
  left: -10000px;
}

.form-section .tabs .tab.active {
  color: #ffffff;
  background-color: #4c4c4c;
  cursor: default;
}

.form-section > .container > .form-item {
  display: none;
}