footer {
  margin-top: 60px;

  .js-footer-expand{
    .more{
      display: none !important;
    }
  }
}

footer .publication {
  font-family: $font;
  font-weight: 700;
  font-size: 20px;
  color: #4c4c4c;
  padding: 43px 0 5px;
}

footer .publication a {
  text-decoration: none;
  color: $color-green-darker;
}

footer .title {
  font-family: $font;
  font-weight: 400;
  font-size: 25px;
  padding: 10px 100px 10px 0;
}

footer .title a {
  text-decoration: none;
  color: #ffffff;
}

footer .description {
  font-family: $font;
  font-weight: 400;
  font-size: 16px;
  color: #4c4c4c;
  padding: 15px 55px 15px 0;
}

footer .img-publication {
  padding-top: 60px;
}

footer .order {
  font-family: $font;
  font-weight: 700;
  font-size: 16px;
  color: #4c4c4c;
  padding: 8px 25px;
  background-color: $color-green-darker;
  margin-top: 0;
  display: table;
  text-decoration: none;
  margin-bottom: 60px;
  @include hoverCta($color-green-darker);
}

.footerPatrimoine {
  background-color: #4c4c4c;
}

.footerPatrimoine .logo {
  margin: 30px 0 0;
  float: left;
  display: block;
  img{
    height: 60px;
  }
  &.logo--patrimoine{
    text-align: center;
    margin-right: 30px;
    text-decoration: none;
    margin-top: 50px;
    span{
      font-family: Georgia, serif;
      color: #ffffff;
      font-size: 30px;
      text-align: center;
      em{
        color: #fd8f3f;
        font-style: normal;
      }
    }
    &.de{
      img{
        width: 105px;
      }
    }
    img {
      height: 25px;
      width: 168px;
    }
    &.nl img {
      width: 83px;
    }
    &.fr img {
      width: 120px;
    }
  }
}

.footerPatrimoine .title {
  font-family: $font;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 0 20px;
}

.footerPatrimoine .description {
  font-family: $font;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
}

.footerPatrimoine .description a {
  color: #ffffff;
  text-decoration: none;
}

.footerPatrimoine .list {
  font-family: $font;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 0;
}

.footerPatrimoine .list a {
  text-decoration: none;
  color: #fd8f3f;
}

.footerPatrimoine .visit-site {
  font-family: $font;
  font-weight: 700;
  font-size: 16px;
  color: #4c4c4c;
  padding: 8px 24px;
  background-color: #a6a6a6;
  margin-top: 30px;
  display: table;
  text-decoration: none;
  margin-bottom: 60px;
  line-height: 1.5;
  transition: all .3s ease;
}

.footerPatrimoine .visit-site:hover {
  background-color: #919090;
}

.footer {
  height: 50px;
  color: #4c4c4c;
  font-size: 12px;
  font-family: $font;
  font-weight: 400;
  padding-top: 15px;
}

.footer .footerMostwanted {
  padding: 0;
  .mw{
    font-weight: $font-bold;
  }
}

.footer a {
  color: #4c4c4c;
  text-decoration: none;
}

.footer a:hover {
  color: #787878;
}

.footerPublication{
  background: $color-green-main;

  .field-subtitle-start,
  .field-subtitle-end,
  p{
    color: $color-green-darker;
  }
}

.footer-row{
  min-height: 50px;
}