.article-details-header {
  padding: 20px 0 3.7em;
}

.article-details-header a {
  color: #4c4c4c;
}

.article-details-header a .icon-arrow3 {
  margin-right: 7px;
  font-size: 12px;
}

.article-details-header a:hover,
.article-details-header a:focus {
  text-decoration: none;
}

.article-details .pictures {
  text-align: center;
  font-size: 0;
}

.article-details .pictures .figure {
  margin: 0;
  display: inline-block;
  /* width: calc(50% - 4px); */
  font-size: 11px;
  text-align: right;
}

// slider

.article-details {
  .pictures {
    .slick-slider {
      figure {
        display: block;
        outline: none;
        width: initial;
        overflow: hidden;
        &:first-child {
          margin: 0;
        }
        &:last-child {
          margin: 0;
        }
        img{
          margin: 0 auto;
          //width: 100%;
        }
      }
    }
  }
}

.article-details .pictures .figure:first-child {
  margin-right: 8px;
}

.article-details .pictures .figure img {
  width: auto;
  height: auto;
  max-height: 328px;
  padding: 0 1px; //fix overlaps image in left/right side
}

.article-details .pictures .figure .copy {
  display: block;
  padding: 3px;
  text-align: right;
}

.article-details .desc {
  padding: 50px 0;
  font-size: 20px;
  text-align: center;
  color: #4c4c4c;
}

.article-details .specs {
  padding: 60px 0;
  border-top: 1px solid #73726c;
}

.article-details .specs .col-sm-6 {
  padding-left: 0;
  padding-right: 40px;
  font-size: 16px;
}

.article-details .specs .col-sm-6:last-child {
  padding-right: 0;
}

.article-details .specs p {
  padding: 3px 5px 14px 0;
  line-height: 1.6;
}

.article-details .specs .r_title {
  float: left;
  width: 136px;
  font-family: $font;
  font-weight: 700;
}

.article-details .specs .r_spec {
  float: left;
  width: calc(100% - 136px);
}

.figure img {
  width: 100%;
  height: auto;
}

.figure figcaption {
  font-size: 11px;
  display: block;
  padding: 3px;
  margin-bottom: 20px;
  overflow: hidden;
}

.figure figcaption .desc {
  width: 70%;
  padding-right: 20px;
  float: left;
}

.figure figcaption .copy {
  width: 30%;
  text-align: right;
  float: right;
}

.c-block {
  display: block;
  margin-top: 0;
}