.icon-arrow4:before {
  content: "\e905";
}

.icon-arrow3:before {
  content: "\e904";
}

.icon-arrow:before {
  content: "\e900";
}

.icon-arrow2:before {
  content: "\e901";
}

.icon-close:before {
  content: "\e902";
}

.icon-menu:before {
  content: "\e903";
}

.icon-home:before {
  content: "\e906";
}

.icon-home3:before {
  content: "\e907";
}

.dropdown-menu .icon-arrow:before {
  content: "\e901";
}

.dropdown-menu .open .icon-arrow:before {
  content: "\e900";
}

.arrow,
.arrow-link {
  font-size: 30px;
  color: #e2d1a6;
  margin-top: -4px;
  margin-bottom: 55px;
  display: block;
}

.icon-arrow4.arrow {
  margin-top: 20px;
  margin-bottom: 40px;
  color: #ffffff;
}

.arrow-link:hover,
.arrow-link:focus {
  color: $color-green-main;
  text-decoration: none;
}