.menu-sec {
  background-color: #004077;
  z-index: 10;
}

.navbar-inverse {
  background: #4c4c4c none;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}

.navbar-inverse .navbar-header {
  margin-top: -50px;
}

.navbar-inverse a.navbar-brand {
  color: #ffffff;
  font-family: $font;
  font-weight: 700;
  font-size: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.navbar-inverse .navbar-nav li a {
  color: #ffffff;
  font-family: $font;
  font-weight: 400;
  font-size: 14px;
}

.navbar-inverse .navbar-nav li.dropdown a {
  //padding-right: 0;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  background: transparent none;
  box-shadow: none;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  background-color: #fd8f3f;
}

.menu .dropdown-toggle {
  color: #ffffff;
  font-family: $font;
  font-weight: 400;
  text-decoration: none;
}

.menu .dropdown-toggle p {
  margin: 0;
  font-weight: $font-bold;
  span{
    margin-left: 10px;
    vertical-align: bottom;
  }
}

.menu {
  position: relative;
  z-index: 3;
  margin-right: 25px;
}

.menuClose {
  display: none;
}

/* DropDown */
.menu img {
  margin-top: -4px;
}

.menu .dropdown {
  padding: 15px 24px 10px 21px;
}

.menu .dropdown.open,
.menu .dropdown:hover {
  background-color: $color-green-main;
}

.menu .dropdown.open a,
.menu .dropdown:hover a {
  //color: $color-green-darker;
}

.menu .dropdown {
  &.open{
    li {
      a {
        color: $color-green-darker;
        &:hover,
        &.active {
          background: $color-green-darker;
          color: #ffffff;

          & + span{
            color: #ffffff;
          }
        }
      }
    }
    .menuOpen{
      color: $color-green-darker;
    }
  }
  &:hover,
  &:focus{
    .menuOpen{
      color: $color-green-darker;
    }
  }
}

/*.dropdown-menu{
  li{
    a{
      &.active{
        background: $color-green-darker;
        color: #ffffff;
      }
    }
  }
}*/

.menu .dropdown.open span,
.menu .dropdown:hover span {
  //color: #ffffff;
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  background-color: $color-green-main;
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.navbar-nav .dropdown-menu {
  background-color: #4C4C4C;
}

.navbar-nav .dropdown-menu > li > a,
.navbar-nav .dropdown-menu > li:first-child > a {
  border-top: 1px solid #646464;
}

.navbar-nav .dropdown-menu > li > a:hover,
.navbar-nav .dropdown-menu > li > a:focus {
  background-color: #fd8f3f;
}

.dropdown-menu > li > a,
.dropdown-menu > li.dropdown-submenu {
  border-top: 1px solid #146b58;
  font-family: $font;
  font-weight: 700;
  font-size: 13px;
  color: $color-green-darker;
  line-height: 32px; 
  padding: 3px 50px 3px 20px;
  cursor: pointer;
  background: none;
}

.dropdown-menu > li:first-child > a {
  border-top: none;
}

.dropdown-submenu .dropdown-menu > li > a:before {
  font-size: 10px;
}

.dropdown-submenu .dropdown-menu > li:first-child > a {
  border-top: 1px solid #4c4c4c;
  box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 0.60);
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background: $color-green-darker;
}

.dropdown-menu > li.dropdown-submenu {
  &.open,
  &:hover,
  &:focus {
    background: $color-green-darker;
    color: #ffffff;

    span{
      color: #ffffff;
    }
  }
  .dropdown-menu{
    li{
      a{
        padding-left: 16px;
        &:before{
          font-family: "icomoon";
          @extend .icon-arrow2;
          padding-right: 16px;
        }
      }
    }
  }
}

.dropdown-menu > li.dropdown-submenu:hover {

}

.dropdown-submenu:hover > .dropdown-menu {
  display: none;
}

.dropdown-menu > li.dropdown-submenu:hover,
.dropdown-menu > li.dropdown-submenu:focus {
  //background-color: $color-green-main;
}

.dropdown-submenu.open > .dropdown-menu {
  display: block;
  width: 100%;
}

.dropdown-submenu.open > .dropdown-menu > li > a {
  background-color: #dbebcd;
  padding-left: 40px;

}

.dropdown-submenu.open > .dropdown-menu > li > a:hover,
.dropdown-submenu.open > .dropdown-menu > li > a:focus {
  background-color: #ffffff;
  color: $color-green-darker;
}

.dropdown-menu > li > span {
  color: $color-green-darker;
  float: right;
  font-size: 14px;
  margin-right: 23px;
  margin-top: -27px;
  display: none;
}

.dropdown-menu{
  &.open{
    .dropdown-submenu{
      span{
        color: #ffffff;
      }
    }
  }
}

.dropdown-menu .dropdown-submenu span {
  //color: #b7a77f;
  float: right;
  font-size: 14px;
  margin-right: -25px;
  margin-top: 10px;
  color: $color-green-darker;
}

.dropdown-submenu .dropdown-menu > li > span {
  color: #4c4c4c;
  float: left;
  font-size: 12px;
  margin-left: 17px;
  margin-right: 23px;
  margin-top: -26px;
}

img.frb-logotop {
  height: 35px;
  float: left;
  &.frb--fondation{
    height: 35px;
    margin-top: -3px;
  }
}

.navbar .navbar-brand:nth-child(2) {
  margin-left: 0;
}

.navbar .dropdown-toggle .icon-arrow {
  font-size: 0.9em;
  margin-left: 6px;
}

.page-homepage-items .menu-sec.affix {
  background-color: #004077;
}