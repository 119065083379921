.bg-top {
  //background-image: radial-gradient(ellipse closest-corner at center, #94938B 0%, #85847D 30%, #64635E 100%);
  background: #004077 url("../images/bg--top.jpg") no-repeat 50% 50%;
  min-height: 611px; //780px;
  margin-top: -47px;
  padding-top: 45px;
  position: relative;
  z-index: 1;

  h1{
    margin-top: 70px;
  }

}

.bg-top h3 {
  color: #e2d1a6;
  font-family: $font;
  font-weight: 400;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
}

.bg-top-simple{
  background: #004077;
  border-bottom-color: $color-green-main;
}

.bg-top-simple .title-top {
  position: relative;
  margin-bottom: 53px;
}

.bg-top-simple .title-top h1 {
  font-size: 40px;
  margin: 15px;
}

.bg-top-simple .title-top.with-nav h1 {
  margin: 15px 50px;
}

.bg-top-simple .arrow-link {
  position: absolute;
  display: block;
  top: 50%;
  margin-top: -15px;
  font-size: 35px;
  color: #ffffff;
}

.bg-top-simple .next {
  right: 0;
}

.bg-top-arrow {
  margin-left: -20px;
  margin-right: -15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  //background-image: linear-gradient(to bottom, transparent 0%, #5b5a56 100%);
}

.more-info{
  .row{
    border-top: 1px solid #d2d2d2 ;
    border-bottom: 1px solid #d2d2d2 ;
    //margin-top: 20px;
    //margin-bottom: 70px;
    padding-bottom: 30px;
  }

  .info{
    padding-right: 0;
    padding-left: 0;
    width: 30%;
    margin-right: 5%;
    &:last-child {
      margin-right: 0;
    }
    h3{
      color: #000000;
      font-family: $font;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 35px;
      margin-top: 70px;
    }
    p{
      color: #4c4c4c;
      font-family: $font;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.6;
      /*@include ellipsis(16px, 1.6, 5);*/
    }
    /*.inner{
      padding: 0 45px 0 0;
    }
    &:last-child{
      .inner{
        padding-right: 0;
      }
    }*/
    &.info1{
      padding: 0 0 0 0;
    }
    &.info2{
      padding-left: 15px;
      padding-right: 15px;
    }
    &.info3{
      padding-left: 30px;
    }

    .min-plus{
      position: absolute;
      top: 90%;
      left: 0;
      margin-left: 0;
    }
  }
}


.more-info .info p {
  margin-bottom: 115px; 
}

.inventory h3 {
  font-family: $font;
  font-weight: 400;
  color: #4c4c4c;
  font-size: 25px;
  margin-bottom: 30px;
  margin-left: 10px;
}

.inventory .share {
  margin-top: 25px;
  padding-right: 27px;
}

.inventory .share span {
  font-family: $font;
  font-weight: 700;
  color: #4c4c4c;
  font-size: 12px;
}

.inventory .share span svg:hover {
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
}

::i-block-chrome, .inventory .share span svg:hover {
  display: initial;
}

.at-share-btn {
  margin: 0 7px !important;
}

.at-share-btn span {
  border-radius: 15px;
}

.content-block {
  clear: both;
  border-top: 1px solid #73726c;
  color: #4c4c4c;
  padding-bottom: 60px;
  padding-top: 60px;
}

.block-separator {
  border-top: 1px solid #73726c;
  margin-top: 0;
  margin-bottom: 60px;
}

.blocks .content-block:first-child {
  border-top: none;
}

.content-block h2 {
  margin: 60px;
  text-align: center;
  line-height: 1.6;
  font-size: 25px;
  font-family: $font;
  font-weight: 700;
}

.content-block p, .content-block ul, .content-block table {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.6;
}

.content-block.left-image .figure {
  width: 40%;
  float: left;
  margin-top: 7px;
}

.content-block.left-image .text-content {
  margin-left: 55px;
  max-width: calc(60% - 55px);
  float: left;
}

.content-block.full-width-image .text-content,
.content-block.no-image-centered .text-content {
  margin: 60px 70px 0 70px;
  text-align: center;
}

.content-block.right-image .figure {
  width: 40%;
  float: right;
  margin-top: 7px;
}

.content-block.right-image .text-content {
  margin-right: 55px;
  max-width: calc(60% - 55px);
  float: left;
}

.content-block.where-to-find {
  position: relative;
  padding-top: 75px;
}

.content-block.where-to-find .figure {
  position: absolute;
  left: 0;
  top: 75px;
  width: 34%;
  float: left;
  margin-top: 10px;
}

.content-block.where-to-find .text-content {
  margin-left: calc(34% + 55px);
  max-width: calc(66% - 55px);
  float: left;
}

.content-block.where-to-find .text-content h2 {
  text-align: left;
  margin: 0;
}