@charset "UTF-8";
/*$font-reg : "euphemia_ucasregular", $font-fallback;
$font-bold : "euphemia_ucasbold", $font-fallback;
$font-italic : "euphemia_ucasitalic", $font-fallback;*/
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700");
body {
  font-family: "PT Sans", sans-serif;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.container.smaller {
  padding-left: 70px;
  padding-right: 70px;
}

h1 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 60px;
  position: relative;
}

.affix {
  top: 0px;
  width: 100%;
}

.anchor-offset {
  display: block;
  position: relative;
  top: -55px;
  visibility: hidden;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.views_infinite_scroll-ajax-loader {
  background: url("../images/spinner.gif") no-repeat 0 0;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
}

.views_infinite_scroll-ajax-loader img {
  display: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*@font-face {
  font-family: 'euphemia_ucasregular';
  src: url('fonts/euphemiaucas-webfont.eot');
  src: local('☺'), url('fonts/euphemiaucas-webfont.woff') format('woff'),
  url('fonts/euphemiaucas-webfont.woff2') format('woff2'),
  url('fonts/euphemiaucas-webfont.ttf') format('truetype'),
  url('fonts/euphemiaucas-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'euphemia_ucasbold';
  src: url('fonts/euphemiaucas-bold-webfont.eot');
  src: local('☺'), url('fonts/euphemiaucas-bold-webfont.woff') format('woff'),
  url('fonts/euphemiaucas-bold-webfont.woff2') format('woff2'),
  url('fonts/euphemiaucas-bold-webfont.ttf') format('truetype'),
  url('fonts/euphemiaucas-bold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'euphemia_ucasitalic';
  src: url('fonts/euphemiaucas-italic-webfont.eot');
  src: url('fonts/euphemiaucas-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/euphemiaucas-italic-webfont.woff2') format('woff2'),
  url('fonts/euphemiaucas-italic-webfont.woff') format('woff'),
  url('fonts/euphemiaucas-italic-webfont.ttf') format('truetype'),
  url('fonts/euphemiaucas-italic-webfont.svg#euphemia_ucasitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}*/
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?2aafhm");
  src: url("fonts/icomoon.eot?2aafhm#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?2aafhm") format("truetype"), url("fonts/icomoon.woff?2aafhm") format("woff"), url("fonts/icomoon.svg?2aafhm#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-top {
  background: #004077 url("../images/bg--top.jpg") no-repeat 50% 50%;
  min-height: 611px;
  margin-top: -47px;
  padding-top: 45px;
  position: relative;
  z-index: 1;
}

.bg-top h1 {
  margin-top: 70px;
}

.bg-top h3 {
  color: #e2d1a6;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
}

.bg-top-simple {
  background: #004077;
  border-bottom-color: #c0e3a1;
}

.bg-top-simple .title-top {
  position: relative;
  margin-bottom: 53px;
}

.bg-top-simple .title-top h1 {
  font-size: 40px;
  margin: 15px;
}

.bg-top-simple .title-top.with-nav h1 {
  margin: 15px 50px;
}

.bg-top-simple .arrow-link {
  position: absolute;
  display: block;
  top: 50%;
  margin-top: -15px;
  font-size: 35px;
  color: #ffffff;
}

.bg-top-simple .next {
  right: 0;
}

.bg-top-arrow {
  margin-left: -20px;
  margin-right: -15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: none;
}

.more-info .row {
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 30px;
}

.more-info .info {
  padding-right: 0;
  padding-left: 0;
  width: 30%;
  margin-right: 5%;
  /*.inner{
      padding: 0 45px 0 0;
    }
    &:last-child{
      .inner{
        padding-right: 0;
      }
    }*/
}

.more-info .info:last-child {
  margin-right: 0;
}

.more-info .info h3 {
  color: #000000;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 35px;
  margin-top: 70px;
}

.more-info .info p {
  color: #4c4c4c;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  /*@include ellipsis(16px, 1.6, 5);*/
}

.more-info .info.info1 {
  padding: 0 0 0 0;
}

.more-info .info.info2 {
  padding-left: 15px;
  padding-right: 15px;
}

.more-info .info.info3 {
  padding-left: 30px;
}

.more-info .info .min-plus {
  position: absolute;
  top: 90%;
  left: 0;
  margin-left: 0;
}

.more-info .info p {
  margin-bottom: 115px;
}

.inventory h3 {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  color: #4c4c4c;
  font-size: 25px;
  margin-bottom: 30px;
  margin-left: 10px;
}

.inventory .share {
  margin-top: 25px;
  padding-right: 27px;
}

.inventory .share span {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  color: #4c4c4c;
  font-size: 12px;
}

.inventory .share span svg:hover {
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
}

::i-block-chrome, .inventory .share span svg:hover {
  display: initial;
}

.at-share-btn {
  margin: 0 7px !important;
}

.at-share-btn span {
  border-radius: 15px;
}

.content-block {
  clear: both;
  border-top: 1px solid #73726c;
  color: #4c4c4c;
  padding-bottom: 60px;
  padding-top: 60px;
}

.block-separator {
  border-top: 1px solid #73726c;
  margin-top: 0;
  margin-bottom: 60px;
}

.blocks .content-block:first-child {
  border-top: none;
}

.content-block h2 {
  margin: 60px;
  text-align: center;
  line-height: 1.6;
  font-size: 25px;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
}

.content-block p, .content-block ul, .content-block table {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.6;
}

.content-block.left-image .figure {
  width: 40%;
  float: left;
  margin-top: 7px;
}

.content-block.left-image .text-content {
  margin-left: 55px;
  max-width: calc(60% - 55px);
  float: left;
}

.content-block.full-width-image .text-content,
.content-block.no-image-centered .text-content {
  margin: 60px 70px 0 70px;
  text-align: center;
}

.content-block.right-image .figure {
  width: 40%;
  float: right;
  margin-top: 7px;
}

.content-block.right-image .text-content {
  margin-right: 55px;
  max-width: calc(60% - 55px);
  float: left;
}

.content-block.where-to-find {
  position: relative;
  padding-top: 75px;
}

.content-block.where-to-find .figure {
  position: absolute;
  left: 0;
  top: 75px;
  width: 34%;
  float: left;
  margin-top: 10px;
}

.content-block.where-to-find .text-content {
  margin-left: calc(34% + 55px);
  max-width: calc(66% - 55px);
  float: left;
}

.content-block.where-to-find .text-content h2 {
  text-align: left;
  margin: 0;
}

.region-secondary-header {
  height: auto;
  /*47px;*/
  position: relative;
  z-index: 2;
}

#back-collection {
  position: absolute;
  display: block;
  float: left;
  margin-left: -20px;
  padding: 14px 0;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  z-index: 5;
  transition: all .3s ease;
}

#back-collection.below {
  z-index: 0;
}

#back-collection:hover,
#back-collection:focus {
  text-decoration: none;
  color: #c0e3a1;
}

footer {
  margin-top: 60px;
}

footer .js-footer-expand .more {
  display: none !important;
}

footer .publication {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #4c4c4c;
  padding: 43px 0 5px;
}

footer .publication a {
  text-decoration: none;
  color: #146b58;
}

footer .title {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 25px;
  padding: 10px 100px 10px 0;
}

footer .title a {
  text-decoration: none;
  color: #ffffff;
}

footer .description {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4c4c4c;
  padding: 15px 55px 15px 0;
}

footer .img-publication {
  padding-top: 60px;
}

footer .order {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #4c4c4c;
  padding: 8px 25px;
  background-color: #146b58;
  margin-top: 0;
  display: table;
  text-decoration: none;
  margin-bottom: 60px;
  transition: .3s all ease;
}

footer .order:hover, footer .order:focus, footer .order:active {
  background: #105646;
}

footer .order:active:focus {
  background: #105646;
}

.footerPatrimoine {
  background-color: #4c4c4c;
}

.footerPatrimoine .logo {
  margin: 30px 0 0;
  float: left;
  display: block;
}

.footerPatrimoine .logo img {
  height: 60px;
}

.footerPatrimoine .logo.logo--patrimoine {
  text-align: center;
  margin-right: 30px;
  text-decoration: none;
  margin-top: 50px;
}

.footerPatrimoine .logo.logo--patrimoine span {
  font-family: Georgia, serif;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
}

.footerPatrimoine .logo.logo--patrimoine span em {
  color: #fd8f3f;
  font-style: normal;
}

.footerPatrimoine .logo.logo--patrimoine.de img {
  width: 105px;
}

.footerPatrimoine .logo.logo--patrimoine img {
  height: 25px;
  width: 168px;
}

.footerPatrimoine .logo.logo--patrimoine.nl img {
  width: 83px;
}

.footerPatrimoine .logo.logo--patrimoine.fr img {
  width: 120px;
}

.footerPatrimoine .title {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 0 20px;
}

.footerPatrimoine .description {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
}

.footerPatrimoine .description a {
  color: #ffffff;
  text-decoration: none;
}

.footerPatrimoine .list {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 0;
}

.footerPatrimoine .list a {
  text-decoration: none;
  color: #fd8f3f;
}

.footerPatrimoine .visit-site {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #4c4c4c;
  padding: 8px 24px;
  background-color: #a6a6a6;
  margin-top: 30px;
  display: table;
  text-decoration: none;
  margin-bottom: 60px;
  line-height: 1.5;
  transition: all .3s ease;
}

.footerPatrimoine .visit-site:hover {
  background-color: #919090;
}

.footer {
  height: 50px;
  color: #4c4c4c;
  font-size: 12px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  padding-top: 15px;
}

.footer .footerMostwanted {
  padding: 0;
}

.footer .footerMostwanted .mw {
  font-weight: 700;
}

.footer a {
  color: #4c4c4c;
  text-decoration: none;
}

.footer a:hover {
  color: #787878;
}

.footerPublication {
  background: #c0e3a1;
}

.footerPublication .field-subtitle-start,
.footerPublication .field-subtitle-end,
.footerPublication p {
  color: #146b58;
}

.footer-row {
  min-height: 50px;
}

.menu-sec {
  background-color: #004077;
  z-index: 10;
}

.navbar-inverse {
  background: #4c4c4c none;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}

.navbar-inverse .navbar-header {
  margin-top: -50px;
}

.navbar-inverse a.navbar-brand {
  color: #ffffff;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.navbar-inverse .navbar-nav li a {
  color: #ffffff;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  background: transparent none;
  box-shadow: none;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  background-color: #fd8f3f;
}

.menu .dropdown-toggle {
  color: #ffffff;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
}

.menu .dropdown-toggle p {
  margin: 0;
  font-weight: 700;
}

.menu .dropdown-toggle p span {
  margin-left: 10px;
  vertical-align: bottom;
}

.menu {
  position: relative;
  z-index: 3;
  margin-right: 25px;
}

.menuClose {
  display: none;
}

/* DropDown */
.menu img {
  margin-top: -4px;
}

.menu .dropdown {
  padding: 15px 24px 10px 21px;
}

.menu .dropdown.open,
.menu .dropdown:hover {
  background-color: #c0e3a1;
}

.menu .dropdown.open li a {
  color: #146b58;
}

.menu .dropdown.open li a:hover, .menu .dropdown.open li a.active {
  background: #146b58;
  color: #ffffff;
}

.menu .dropdown.open li a:hover + span, .menu .dropdown.open li a.active + span {
  color: #ffffff;
}

.menu .dropdown.open .menuOpen {
  color: #146b58;
}

.menu .dropdown:hover .menuOpen, .menu .dropdown:focus .menuOpen {
  color: #146b58;
}

/*.dropdown-menu{
  li{
    a{
      &.active{
        background: $color-green-darker;
        color: #ffffff;
      }
    }
  }
}*/
.dropdown-menu {
  border: none;
  border-radius: 0;
  background-color: #c0e3a1;
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.navbar-nav .dropdown-menu {
  background-color: #4C4C4C;
}

.navbar-nav .dropdown-menu > li > a,
.navbar-nav .dropdown-menu > li:first-child > a {
  border-top: 1px solid #646464;
}

.navbar-nav .dropdown-menu > li > a:hover,
.navbar-nav .dropdown-menu > li > a:focus {
  background-color: #fd8f3f;
}

.dropdown-menu > li > a,
.dropdown-menu > li.dropdown-submenu {
  border-top: 1px solid #146b58;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 13px;
  color: #146b58;
  line-height: 32px;
  padding: 3px 50px 3px 20px;
  cursor: pointer;
  background: none;
}

.dropdown-menu > li:first-child > a {
  border-top: none;
}

.dropdown-submenu .dropdown-menu > li > a:before {
  font-size: 10px;
}

.dropdown-submenu .dropdown-menu > li:first-child > a {
  border-top: 1px solid #4c4c4c;
  box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 0.6);
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background: #146b58;
}

.dropdown-menu > li.dropdown-submenu.open, .dropdown-menu > li.dropdown-submenu:hover, .dropdown-menu > li.dropdown-submenu:focus {
  background: #146b58;
  color: #ffffff;
}

.dropdown-menu > li.dropdown-submenu.open span, .dropdown-menu > li.dropdown-submenu:hover span, .dropdown-menu > li.dropdown-submenu:focus span {
  color: #ffffff;
}

.dropdown-menu > li.dropdown-submenu .dropdown-menu li a {
  padding-left: 16px;
}

.dropdown-menu > li.dropdown-submenu .dropdown-menu li a:before {
  font-family: "icomoon";
  padding-right: 16px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: none;
}

.dropdown-submenu.open > .dropdown-menu {
  display: block;
  width: 100%;
}

.dropdown-submenu.open > .dropdown-menu > li > a {
  background-color: #dbebcd;
  padding-left: 40px;
}

.dropdown-submenu.open > .dropdown-menu > li > a:hover,
.dropdown-submenu.open > .dropdown-menu > li > a:focus {
  background-color: #ffffff;
  color: #146b58;
}

.dropdown-menu > li > span {
  color: #146b58;
  float: right;
  font-size: 14px;
  margin-right: 23px;
  margin-top: -27px;
  display: none;
}

.dropdown-menu.open .dropdown-submenu span {
  color: #ffffff;
}

.dropdown-menu .dropdown-submenu span {
  float: right;
  font-size: 14px;
  margin-right: -25px;
  margin-top: 10px;
  color: #146b58;
}

.dropdown-submenu .dropdown-menu > li > span {
  color: #4c4c4c;
  float: left;
  font-size: 12px;
  margin-left: 17px;
  margin-right: 23px;
  margin-top: -26px;
}

img.frb-logotop {
  height: 35px;
  float: left;
}

img.frb-logotop.frb--fondation {
  height: 35px;
  margin-top: -3px;
}

.navbar .navbar-brand:nth-child(2) {
  margin-left: 0;
}

.navbar .dropdown-toggle .icon-arrow {
  font-size: 0.9em;
  margin-left: 6px;
}

.page-homepage-items .menu-sec.affix {
  background-color: #004077;
}

/* Search Form */
.searchForm {
  padding-top: 30px;
  padding-bottom: 20px;
  overflow: hidden;
}

.searchAdvanceForm {
  padding-top: 30px;
  padding-bottom: 30px;
  display: none;
}

.searchAdvanceForm .inventory {
  background: none;
  padding: 0;
  margin: 0;
}

.searchAdvanceForm .form-group {
  position: relative;
}

.searchAdvanceForm .form-group label {
  position: absolute;
  left: -120px;
  top: 10px;
  color: #146b58;
  font-weight: 700;
}

.searchAdvanceForm .textAdvance span {
  color: #146b58;
}

.searchAdvanceForm .closeAdvance p {
  color: #146b58;
  font-weight: 700;
}

.searchAdvanceForm .closeAdvance p span {
  color: #146b58;
}

.searchAdvanceForm .search--submit .search-btn {
  margin-left: 0;
}

.search-form {
  margin-bottom: 0;
  background: #c0e3a1;
}

.search-form .form-inline {
  margin-bottom: 10px;
}

.search-form .form-control {
  border: medium none;
  border-radius: 0;
  width: 500px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  height: 40px;
}

.search-btn {
  background: #146b58;
  border: medium none;
  border-radius: 0;
  margin-left: 7px;
  width: 150px;
  height: 41px;
  margin-top: -1px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  transition: .3s all ease;
}

.search-btn:hover, .search-btn:focus, .search-btn:active {
  background: #105646;
}

.search-btn:active:focus {
  background: #105646;
}

.search-btn img {
  width: 22px;
  height: 22px;
}

.advanceSearch {
  color: #146b58;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  margin-top: 22px;
}

.advanceSearch span {
  font-size: 14px;
}

.advanceSearch .advanceSearchText {
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.search-form label {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  color: #4c4c4c;
  font-size: 14px;
  width: 120px;
  text-align: left;
}

.textAdvance {
  margin-bottom: 30px;
}

.textAdvance span {
  font-size: 25px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  color: #4c4c4c;
}

.closeAdvance {
  margin-bottom: -44px;
  margin-top: 5px;
  cursor: pointer;
  padding-right: 12px;
}

.closeAdvance span {
  font-size: 17px;
  vertical-align: calc(-2px);
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  color: #4c4c4c;
}

*::-ms-backdrop, .closeAdvance span {
  vertical-align: sub;
}

/* header search */
#header-search {
  z-index: 3;
}

#header-search.affix {
  margin-top: 47px;
}

#header-search-toggle {
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  padding: 24px 30px;
  top: 0;
  right: 120px;
  text-indent: -9999em;
  background: none;
  z-index: 5;
}

#header-search-toggle:after {
  content: "";
  background: url("../images/loupe.svg") no-repeat center;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#header-search-toggle.open {
  background: #c0e3a1;
}

#header-search-toggle.below {
  z-index: 0;
}

#header-search .container {
  position: relative;
}

#header-search .search-form {
  overflow: hidden;
  display: none;
}

#header-search .search-form.open {
  height: auto;
}

.search-results-title h2 {
  margin: 50px;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  color: #4c4c4c;
  font-size: 28px;
}

.container.search-results-form {
  margin-bottom: 8px;
}

.search-results-form .search-form {
  margin-right: 1px;
  overflow: hidden;
  display: none;
}

.search-results-form .container {
  width: 100%;
}

.search-results-form .modif-button-container {
  background-color: #c0e3a1;
  padding: 18px;
}

.search-results-form .modif-button-container .buttons {
  background-color: #146b58;
  color: #ffffff;
  transition: .3s all ease;
}

.search-results-form .modif-button-container .buttons:hover, .search-results-form .modif-button-container .buttons:focus, .search-results-form .modif-button-container .buttons:active {
  background: #105646;
}

.search-results-form .modif-button-container .buttons:active:focus {
  background: #105646;
}

.search-results-form-header {
  padding: 15px 20px;
  font-size: 18px;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  color: #ffffff;
  background-color: #146b58;
  margin-right: 1px;
}

.icon-arrow4:before {
  content: "\e905";
}

.icon-arrow3:before {
  content: "\e904";
}

.icon-arrow:before {
  content: "\e900";
}

.icon-arrow2:before, .dropdown-menu > li.dropdown-submenu .dropdown-menu li a:before {
  content: "\e901";
}

.icon-close:before {
  content: "\e902";
}

.icon-menu:before {
  content: "\e903";
}

.icon-home:before {
  content: "\e906";
}

.icon-home3:before {
  content: "\e907";
}

.dropdown-menu .icon-arrow:before {
  content: "\e901";
}

.dropdown-menu .open .icon-arrow:before {
  content: "\e900";
}

.arrow,
.arrow-link {
  font-size: 30px;
  color: #e2d1a6;
  margin-top: -4px;
  margin-bottom: 55px;
  display: block;
}

.icon-arrow4.arrow {
  margin-top: 20px;
  margin-bottom: 40px;
  color: #ffffff;
}

.arrow-link:hover,
.arrow-link:focus {
  color: #c0e3a1;
  text-decoration: none;
}

.article-details-header {
  padding: 20px 0 3.7em;
}

.article-details-header a {
  color: #4c4c4c;
}

.article-details-header a .icon-arrow3 {
  margin-right: 7px;
  font-size: 12px;
}

.article-details-header a:hover,
.article-details-header a:focus {
  text-decoration: none;
}

.article-details .pictures {
  text-align: center;
  font-size: 0;
}

.article-details .pictures .figure {
  margin: 0;
  display: inline-block;
  /* width: calc(50% - 4px); */
  font-size: 11px;
  text-align: right;
}

.article-details .pictures .slick-slider figure {
  display: block;
  outline: none;
  width: initial;
  overflow: hidden;
}

.article-details .pictures .slick-slider figure:first-child {
  margin: 0;
}

.article-details .pictures .slick-slider figure:last-child {
  margin: 0;
}

.article-details .pictures .slick-slider figure img {
  margin: 0 auto;
}

.article-details .pictures .figure:first-child {
  margin-right: 8px;
}

.article-details .pictures .figure img {
  width: auto;
  height: auto;
  max-height: 328px;
  padding: 0 1px;
}

.article-details .pictures .figure .copy {
  display: block;
  padding: 3px;
  text-align: right;
}

.article-details .desc {
  padding: 50px 0;
  font-size: 20px;
  text-align: center;
  color: #4c4c4c;
}

.article-details .specs {
  padding: 60px 0;
  border-top: 1px solid #73726c;
}

.article-details .specs .col-sm-6 {
  padding-left: 0;
  padding-right: 40px;
  font-size: 16px;
}

.article-details .specs .col-sm-6:last-child {
  padding-right: 0;
}

.article-details .specs p {
  padding: 3px 5px 14px 0;
  line-height: 1.6;
}

.article-details .specs .r_title {
  float: left;
  width: 136px;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
}

.article-details .specs .r_spec {
  float: left;
  width: calc(100% - 136px);
}

.figure img {
  width: 100%;
  height: auto;
}

.figure figcaption {
  font-size: 11px;
  display: block;
  padding: 3px;
  margin-bottom: 20px;
  overflow: hidden;
}

.figure figcaption .desc {
  width: 70%;
  padding-right: 20px;
  float: left;
}

.figure figcaption .copy {
  width: 30%;
  text-align: right;
  float: right;
}

.c-block {
  display: block;
  margin-top: 0;
}

.contact {
  margin-bottom: 60px;
}

.contact .logo-frb {
  display: block;
  width: 340px;
  height: 137px;
  background-image: url("../images/logo-FRB-gray.gif");
  margin: 60px auto 35px;
  text-indent: -9999em;
}

.contact .buttons.email {
  margin: 30px auto;
}

.contact .address {
  font-size: 20px;
  color: #4c4c4c;
  line-height: 1.8;
}

.contact h2 {
  margin-bottom: 30px;
  color: #4c4c4c;
  font-size: 25px;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
}

.contact .contacts-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact .contacts-list li {
  display: inline-block;
  width: 32%;
  vertical-align: top;
  margin-top: 75px;
  padding: 0 30px;
}

.contact .contacts-list h3 {
  color: #fd8f3f;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
}

.contact .contacts-list h4 {
  color: #4c4c4c;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.contact a.email-link,
.contact a.phone {
  display: block;
  color: #4c4c4c;
}

.contact a.email-link:before {
  display: inline-block;
  content: '';
  margin-right: 10px;
  width: 18px;
  height: 12px;
  background-image: url("images/ico-mail-gray.png");
}

.contact a.phone:before {
  display: inline-block;
  content: '';
  margin-right: 10px;
  width: 17px;
  height: 17px;
  background-image: url("images/ico-phone.png");
}

.form-section {
  margin-bottom: -60px;
  padding: 65px 0;
}

.form-section.contact-form {
  background-color: #d4d4d4;
}

.form-section h2 {
  margin-top: 0;
  margin-bottom: 50px;
  text-align: center;
  font-size: 25px;
  color: #4c4c4c;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
}

.form-section h2.top-space {
  margin-top: 50px;
}

.form-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  color: #4c4c4c;
}

.form-section label {
  width: 33.3%;
  margin-top: 10px;
  text-align: right;
  float: left;
}

.form-section label.upper {
  margin-top: 0;
}

.form-section label.file-input-label {
  width: 190px;
}

.form-section .buttons.submit {
  margin-left: 33.3%;
  margin-top: 55px;
}

.form-section .annexe_attachment {
  text-align: left;
  border: 1px solid #c0e3a1;
  padding: 2px;
  width: 500px;
  margin: 0 5px 0 0;
}

.form-section .file-input-block {
  margin-bottom: 10px;
}

.form-section .file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form-section .file-input-button {
  display: block;
  float: left;
  padding: 7px;
  width: 190px;
  text-align: center;
  font-size: 14px;
  color: #146b58;
  background-color: #c0e3a1;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
}

.form-section .file-input-text {
  display: block;
  float: left;
  width: 304px;
  padding: 7px 20px;
  font-size: 14px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
}

.form-section .buttons.upload {
  min-width: 190px;
}

.form-section .buttons.add-file-input {
  margin-left: 190px;
  height: 40px;
  font-size: 31px;
  line-height: 0;
}

.form-section .tabs {
  margin-bottom: 70px;
}

.form-section .tabs .tab {
  padding: 10px;
  margin: 0;
  text-align: center;
  background-color: #a6a6a6;
  border-left: 1px solid #ffffff;
  cursor: pointer;
}

.form-section .tabs .tab:first-child {
  border-left: none;
}

.form-section .tabs .tab .state {
  position: absolute;
  left: -10000px;
}

.form-section .tabs .tab.active {
  color: #ffffff;
  background-color: #4c4c4c;
  cursor: default;
}

.form-section > .container > .form-item {
  display: none;
}

.buttons {
  display: inline-block;
  padding: 9px 25px;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-decoration: none;
  background-color: #146b58;
  border: 0;
  transition: .3s all ease;
}

.buttons:hover, .buttons:focus, .buttons:active {
  background: #105646;
}

.buttons:active:focus {
  background: #105646;
}

.buttons.email:after {
  display: inline-block;
  content: '';
  width: 18px;
  height: 12px;
  background: url("../images/ico-mail.png") no-repeat;
  margin-left: 20px;
}

.buttons.orange {
  background-color: #fd8f3f;
  color: #ffffff;
}

.buttons.orange:hover {
  background-color: #fda565;
}

.buttons.gray {
  background-color: #4c4c4c;
  color: #ffffff;
}

.buttons.gray:hover {
  background-color: #707070;
}

.plus {
  display: inline-block;
}

.plus span {
  background-color: #146b58;
  border-radius: 0;
  color: #ffffff;
  display: inline-block;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  height: auto;
  line-height: 1;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  transition: .3s all ease;
}

.plus span:hover, .plus span:focus, .plus span:active {
  background: #105646;
}

.plus span:active:focus {
  background: #105646;
}

.min-plus a {
  display: block;
}

.min-plus a span {
  background-color: #146b58;
  border-radius: 0;
  color: #ffffff;
  display: inline-block;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  height: auto;
  line-height: 1;
  padding: 8px 25px;
  width: auto;
  transition: .3s all ease;
}

.min-plus a span:hover, .min-plus a span:focus, .min-plus a span:active {
  background: #105646;
}

.min-plus a span:active:focus {
  background: #105646;
}

.more span {
  font-size: 75px;
  line-height: 1;
  color: #ffffff;
}

.dropdown-menu {
  min-width: 240px;
}

.icon-close {
  vertical-align: text-top;
}

.icon-menu {
  font-size: 29px;
  line-height: 22px;
  vertical-align: text-top;
  display: inline-block;
}

*::-ms-backdrop, .icon-menu {
  vertical-align: bottom;
}

/*custom dropdowns*/
.form-select {
  margin-bottom: 0;
  width: 100%;
}

.form-select .dk-selected {
  font-size: 14px;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  padding: 12px 50px 12px 10px;
  line-height: 1.1;
  text-align: left;
  border: none;
  color: #4c4c4c;
  background: #ffffff;
  border-radius: 0;
}

.form-select .dk-selected:before {
  border-width: 0.4em 0.4em 0;
  border-top-color: #4c4c4c;
  margin: -0.2em 1em 0 0;
  z-index: 2;
}

.form-select .dk-selected:after {
  top: 0;
  right: 0;
  height: 100%;
  width: 38px;
  border-left: 1px solid #ffffff;
  background-color: #ffffff !important;
  margin: 0;
}

.form-select .dk-selected:hover:before,
.form-select .dk-selected:focus:before {
  border-top-color: #4c4c4c;
}

.form-select .dk-select-options {
  border: none;
  font-size: 14px;
  color: black;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  max-height: 25em;
}

.form-select .dk-select-options .dk-option-highlight {
  background-color: #ffffff;
  color: #146b58;
}

.form-select .dk-option-selected {
  background-color: #efe1bd;
  color: #4c4c4c;
}

.form-select .dk-option {
  line-height: 1;
  padding: 12px 10px;
  border-top: 1px solid #146b58;
  color: #146b58;
  background: #dbebcd;
}

.form-select.dk-select-open-down .dk-selected {
  border-radius: 0;
  background: #146b58;
  color: #ffffff;
}

.form-select.dk-select-open-down .dk-selected:after {
  background: #146b58 !important;
}

.form-select.dk-select-open-up .dk-selected {
  border-radius: 0;
}

.form-select.dk-select-open-up .dk-selected:before,
.form-select.dk-select-open-down .dk-selected:before {
  border-width: 0 0.4em 0.4em;
  border-bottom-color: #4c4c4c;
}

.form-select.dk-select-open-up .dk-select-options {
  border-radius: 0;
}

.form-select.dk-select-open-down .dk-select-options {
  border-radius: 0;
}

.form-select.error .dk-selected {
  color: #e5342b;
}

.form-select .dk-selected {
  color: #4c4c4c;
  background: #ffffff;
  border: 1px solid transparent;
}

.form-select .dk-selected:before {
  content: "\e905";
  border: none;
  margin: 0;
  transform: translateY(-50%);
  right: 20px;
  font-family: "icomoon";
}

.form-select .dk-selected:after {
  border-left: none;
  background-color: #ffffff !important;
}

.form-select .dk-selected:hover:before,
.form-select .dk-selected:focus:before {
  border: none;
}

.form-select.dk-select-open-up .dk-selected:before,
.form-select.dk-select-open-down .dk-selected:before {
  border: none;
}

.dropdown .glyphicon-menu-down {
  font-size: 12px;
}

.form-item-sort-order .form-select.dk-select-open-down .dk-selected {
  color: #4c4c4c;
}

.form-item-sort-order .form-select .dk-selected {
  background: none;
  border: 1px solid #b5b5b5;
}

.form-item-sort-order .form-select .dk-selected:after {
  background: none !important;
}

.form-item-sort-order .form-select .dk-select-options .dk-option-highlight {
  background: #ffffff;
}

.form-item-sort-order .form-select .dk-option {
  background: #e8e8e8;
  color: #4c4c4c;
  border-top: 1px solid #b5b5b5;
}

.filters {
  margin-left: 6px;
}

.filters > div {
  padding-right: 9px;
  padding-left: 20px;
}

.filters > div:first-child {
  padding-right: 0;
  padding-left: 10px;
}

.filters label {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  color: #4c4c4c;
  font-size: 12px;
  padding-right: 20px;
  float: left;
}

.filters input {
  border: none;
  background-color: transparent;
  border-bottom: solid 1px #b7a77f;
  height: 15px;
  width: 100%;
  border-radius: 0;
}

.filters .filtersDiv {
  overflow: hidden;
  padding-right: 6px;
  display: none;
}

.inventory {
  margin-bottom: 10px;
  margin-right: 1px;
  background: #e8e8e8;
  padding: 15px 10px;
}

.inventory .dropdown {
  padding: 0;
  width: 185px;
  display: inline-block;
}

.inventory .dropdown .description {
  display: none;
}

.inventory .dropdown1 {
  /*padding-left: 14px;
  padding-right: 9px;*/
}

.inventory .dropdown2 {
  padding-left: 3px;
  padding-right: 6px;
}

.inventory .dropdown3 {
  padding-left: 6px;
  padding-right: 3px;
}

.inventory .dropdown4 {
  /*padding-left: 9px;
  padding-right: 14px;
  float: right;*/
  float: none;
  padding: 0;
}

.inventory .dropdown4 .form-item {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.object:hover:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 10px solid #146b58;
  border-bottom: 50px solid #146b58;
  z-index: 1;
}

.object a {
  position: relative;
  text-decoration: none;
}

.object img {
  height: auto;
  width: 237px;
  border: solid 1px rgba(186, 182, 173, 0.5);
  border-bottom: none;
}

.object .title {
  border: solid 1px rgba(186, 182, 173, 0.5);
  border-top: none;
  width: 237.5px;
  height: 50px;
  display: table;
  align-items: center;
  z-index: 2;
  position: relative;
}

.object .title div {
  margin: auto;
  padding: 10px 20px;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #4c4c4c;
  display: table-cell;
  vertical-align: middle;
}

.object:hover .title {
  border: 1px solid transparent;
}

.object:hover .title div {
  background-color: #146b58;
}

.object .title div span a {
  color: #4c4c4c;
}

.object:hover .title div span a {
  color: white;
}

.inventory-objects {
  padding: 0;
}

.inventory-objects .container {
  overflow: hidden;
}

.inventory-objects-content {
  overflow: hidden;
}

.masonry-processed {
  max-width: 1000px;
}

.masonry-processed:after {
  content: '';
  display: block;
  clear: both;
}

.grid-item {
  background-color: white;
  border-style: none;
  width: 238px;
  float: left;
  margin: 0 0 10px;
  padding: 0;
}

#inventory-top {
  position: relative;
  top: -48px;
}

.captionright {
  text-align: right;
  font-size: 0.8em;
}

.inventory-item {
  cursor: pointer;
  transition: 0.3s;
}

.inventory-item:hover {
  opacity: 0.8;
}

.filters--head {
  padding: 65px 0;
}

.filters--head h3 {
  font-size: 40px;
  margin: 0;
}

.filters-container {
  display: flex;
  justify-content: space-between;
}

section:not(.block-system) input[type='text'],
section:not(.block-system) input[type='email'],
section:not(.block-system) input[type='search'],
section:not(.block-system) input[type='password'],
section:not(.block-system) input[type='tel'],
section:not(.block-system) textarea {
  width: 500px;
  padding: 10px 15px;
  margin-bottom: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #4c4c4c;
  -webkit-user-select: text;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
}

section:not(.block-system) input[type='text'].bordered,
section:not(.block-system) input[type='email'].bordered,
section:not(.block-system) input[type='search'].bordered,
section:not(.block-system) input[type='password'].bordered,
section:not(.block-system) input[type='tel'].bordered,
section:not(.block-system) textarea.bordered {
  border: 1px solid #c0e3a1;
}

section:not(.block-system) input[type='text'].error,
section:not(.block-system) input[type='email'].error,
section:not(.block-system) input[type='search'].error,
section:not(.block-system) input[type='password'].error,
section:not(.block-system) input[type='tel'].error,
section:not(.block-system) textarea.error {
  color: #e73131;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
}

input[type='text'].error.bordered, input[type='email'].error.bordered, input[type='search'].error.bordered,
input[type='password'].error.bordered, input[type='tel'].error.bordered, textarea.error.bordered {
  border: 1px solid #e73131;
}

section:not(.block-system) input[type="file"] {
  border: 1px solid;
}

section:not(.block-system) label {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  color: #4c4c4c;
  font-size: 14px;
  padding-right: 20px;
}

section:not(.block-system) textarea {
  resize: none;
  height: 140px;
  margin-bottom: 0;
  vertical-align: top;
}

section:not(.block-system) select {
  padding: 9px;
  border: 1px solid #c0e3a1;
}

select.error {
  border: 1px solid #e73131;
}

.field-block {
  float: left;
  margin-bottom: 10px;
}

.form-error-message {
  display: none;
  padding: 10px 15px;
  background-color: #e73131;
  color: #ffffff;
}

fieldset > legend {
  display: none;
}

section.confirmation {
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  text-align: center;
}

.starter-template {
  padding: 40px 15px;
  text-align: center;
}

.description {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  padding: 70px 75px;
  color: #4c4c4c;
  line-height: 1.5;
}

.description span {
  display: block;
  /*margin-top: 30px;*/
}

div.form-filter {
  width: 100%;
}

div.box-filter {
  width: 100%;
}

.filters label.homeview {
  color: #4c4c4c;
  float: left;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}

.filters label.homeview.label--inventory {
  margin-left: 37px;
}

hr.filters {
  border-color: #4c4c4c;
  float: left;
  margin-bottom: 0;
  margin-top: 10px;
  padding-bottom: 0;
  width: 90%;
}

div.field-body p {
  margin-bottom: 30px;
}

a.arrowlink {
  text-decoration: none;
}

span.form-required {
  color: #4c4c4c;
}

div.clearfix {
  margin-bottom: 0;
  margin-top: 0;
}

div.clearfix label {
  margin-right: 0;
}

.page-homepage-items #back-collection {
  display: none;
}

.page-homepage-items .menu-sec {
  background-color: #004077;
}

.view-search-homepage .view-content.grid {
  position: relative;
}

.image-modal {
  display: none;
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9);
}

.image-modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  padding-bottom: 30px;
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

.image-modal-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.image-modal-close:hover,
.image-modal-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.slick-slider .slick-dots {
  text-align: center;
  padding: 0;
  margin-top: 0;
  margin-bottom: 40px;
}

.slick-slider .slick-dots li {
  display: inline-block;
}

.slick-slider .slick-dots li button {
  display: block;
  border-radius: 100%;
  background: #e8e8e8;
  width: 20px;
  height: 20px;
  margin-left: 15px;
  border: none;
}

.slick-slider .slick-dots li button:focus, .slick-slider .slick-dots li button:active {
  outline: none;
}

.slick-slider .slick-dots li.slick-active button {
  background: #146b58;
}

.js-slider {
  display: none;
}

.js-slider.slick-initialized {
  display: block;
}

.at-icon-wrapper {
  border-radius: 100% !important;
  /*width: 34px !important;
  height: 34px !important;
  line-height: 38px !important;
  .at-icon{
    width: 32px !important;
    height: 27px !important;
  }*/
}

.addthis_toolbox {
  margin-left: 5px;
}

.social-share {
  display: inline-block;
  width: 34px;
  height: 34px;
  transition: all .3s ease;
  margin-left: 8px;
}

.social-share:hover {
  transform: translateY(-5px);
}

.social-share.social-share--tw {
  background: url("../images/share/share_tw.svg") no-repeat 0 0;
}

.social-share.social-share--fb {
  background: url("../images/share/share_fb.svg") no-repeat 0 0;
}

.social-share.social-share--mail {
  background: url("../images/share/share_mail.svg") no-repeat 0 0;
}

.social-share.social-share--lk {
  background: url("../images/share/share_lk.svg") no-repeat 0 0;
}

@media (min-width: 1200px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
    width: 1030px;
  }
}

@media (max-width: 1199px) {
  .object img {
    width: 229px;
  }
  .object .title {
    width: 229px;
  }
  .masonry-processed {
    max-width: 940px;
  }
  .grid-item {
    width: 229px;
  }
  .inventory {
    margin-right: 2px;
  }
  .object .title div {
    font-size: 11px;
  }
}

@media (min-width: 991px) {
  .blocks .content-block:first-child {
    padding-bottom: 55px;
  }
  .searchf .form-group {
    width: 500px;
  }
  .search-form .form-inline {
    width: 670px;
    margin: 0 auto 10px;
  }
}

@media (max-width: 991px) {
  .form-inline .form-control {
    width: 440px;
  }
  input[type='text'], input[type='email'], input[type='search'],
  input[type='password'], input[type='tel'], textarea {
    width: 400px;
  }
  .form-section label.file-input-label {
    width: 100px;
  }
  .form-section .buttons.add-file-input {
    margin-left: 100px;
  }
  .form-section .annexe_attachment {
    width: 400px;
  }
  .form-section .file-input-text {
    display: block;
    float: left;
    width: 204px;
    padding: 7px 20px;
    font-size: 14px;
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
  }
  /*    .inventory .dropdown a {
          font-size: 13px;
          padding-left: 10px;
          padding-right: 10px;
      }

      .inventory .dropdown.dropdown1,
      .inventory .dropdown.dropdown1 .dropdown-menu {
          padding-left: 10px;
          padding-right: 30px;
      }
      .inventory .dropdown2,
      .inventory .dropdown.dropdown2 .dropdown-menu {
         margin-left: -23px;
      }
      .inventory .dropdown.dropdown2 .dropdown-menu {
          margin-left: 0;
      }
      .inventory .dropdown3,
      .inventory .dropdown.dropdown3 .dropdown-menu {
          margin-left: -1px;
          padding-left: 0;
          padding-right: 32px;
      }
      .inventory .dropdown.dropdown3 .dropdown-menu {
          margin-left: 0px;
      }
      .inventory .dropdown.dropdown4,
      .inventory .dropdown.dropdown4 .dropdown-menu {
          margin-left: -40px;
          padding-left: 15px;
          padding-right: 6px;
      }
      .inventory .dropdown.dropdown4 .dropdown-menu {
          margin-left: 0px;
      }*/
  .object img {
    width: 100%;
  }
  .object .title {
    width: 100%;
  }
  .masonry-processed {
    max-width: 720px;
  }
  .grid-item {
    width: 234px;
  }
  .footerPatrimoine .visit-site {
    font-size: 13px;
  }
  .topImage {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .az-element.az-image {
    text-align: center;
  }
  .az-element.az-image figcaption {
    text-align: center !important;
  }
  .az-element.az-text {
    margin-top: 60px;
  }
  .az-element.az-text li {
    text-align: left;
  }
  .az-element.az-row:first-child .az-column:first-child .az-element.az-text {
    margin-top: 0;
  }
  .az-element.az-separator {
    margin: 40px 0;
  }
  .masonry-processed {
    max-width: 767px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .masonry-processed {
    margin: 0 -20px;
  }
  .navbar-toggle {
    visibility: hidden;
  }
  .navbar-toggle.collapsed {
    visibility: visible;
  }
  .closeMenu {
    margin-top: 10px;
    text-align: right;
    margin-right: 0;
  }
  .closeMenu .icon-close {
    font-size: 18px;
    cursor: pointer;
    color: #ffffff;
  }
  .navbar {
    min-height: 37px;
  }
  .navbar-inverse {
    z-index: 2;
  }
  .navbar-inverse .navbar-header {
    margin-top: 0;
  }
  .navbar-brand {
    width: 100%;
    height: 30px;
  }
  .navbar-brand > img {
    margin: 2px auto;
    width: 121px;
    height: auto;
  }
  #navbar {
    border: none;
    box-shadow: none;
    overflow: hidden;
  }
  #navbar .logo img {
    margin: 8px auto 10px;
    width: 121px;
  }
  #navbar .logo-FRB {
    margin: -10px auto 25px;
    width: 110px;
  }
  #navbar .logo-FRB img {
    height: 14px;
    float: left;
  }
  .navbar-nav {
    margin-bottom: 4px;
  }
  .navbar-inverse .navbar-nav li > a,
  .navbar-inverse .navbar-nav li.active > a {
    text-align: center;
    background-color: #646464;
    margin: 0 15px 10px;
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
  .navbar-inverse .navbar-nav li:first-child > a {
    padding: 8px 15px;
  }
  .navbar-inverse .navbar-nav li > a:hover,
  .navbar-inverse .navbar-nav li.active > a:hover {
    background-color: #FD8F3F;
  }
  .navbar-inverse a.navbar-brand {
    padding-top: 7px;
  }
  .navbar-toggle {
    margin-top: -29px;
    border: none;
    padding: 7px 0 0;
  }
  .navbar-toggle span {
    border: medium none;
    border-radius: 30px;
    display: block;
    font-size: 30px;
    height: 20px;
    line-height: 20px;
    width: 20px;
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    background-color: transparent;
    color: #ffffff;
  }
  .navbar-inverse .navbar-toggle:focus,
  .navbar-inverse .navbar-toggle:hover {
    background-color: transparent;
  }
  .navbar-inverse .navbar-toggle:hover span {
    background-color: #919090;
  }
  .menu .dropdown {
    padding: 10px 6px 10px;
  }
  .menu .dropdown.open,
  .menu .dropdown:hover {
    background-color: transparent;
  }
  .menu-sec {
    padding: 0;
    width: 100%;
  }
  .menu .dropdown-toggle p {
    font-size: 14px;
  }
  .menu .dropdown-toggle p span {
    margin-left: 0;
  }
  .cloaseDropdown {
    background-color: #73726c;
    color: #ffffff;
    height: 40px;
  }
  #header-search.affix {
    margin-top: 39px;
  }
  #header-search-toggle {
    text-align: left;
    padding: 20px 30px;
    right: 90px;
    left: auto;
  }
  body:not(.page-homepage-items) #back-collection {
    margin-left: 20px;
  }
  #back-collection {
    margin-left: 30px;
    padding: 10px 0;
  }
  #back-collection .text {
    display: none;
  }
  .menu-sec .container {
    padding: 0;
  }
  .menu .dropdown.open a {
    font-size: 13px;
  }
  .menu .dropdown:hover .menuOpen, .menu .dropdown:focus .menuOpen {
    color: white;
  }
  .dropdown-menu > li > a,
  .dropdown-menu > li.dropdown-submenu {
    font-size: 14px;
    padding: 8px 50px 8px 20px;
  }
  .dropdown-menu > li > span {
    margin-top: -27px;
    font-size: 14px;
    margin-right: 15px;
  }
  .dropdown-menu .dropdown-submenu span {
    font-size: 14px;
    margin-right: -34px;
  }
  .dropdown-submenu .dropdown-menu > li > span {
    margin-top: -25px;
    font-size: 10px;
    margin-left: 19px;
  }
  .menu-sec .menu {
    width: 100%;
    text-align: right;
    margin-right: 0;
  }
  .menuClose {
    display: none;
    text-align: left;
    color: #ffffff;
    padding-left: 10px;
    font-size: 12px;
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
  }
  .icon-menu {
    font-size: 26px;
    line-height: 19px;
  }
  .menuOpen {
    font-size: 12px;
    padding-right: 12px;
  }
  .menu-sec .dropdown-menu {
    width: 100%;
  }
  .menu-sec .menu .dropdown.open {
    background-color: #004077;
    color: white;
  }
  .dropdown-menu > li:first-child > a {
    border-top: 1px solid #4c4c4c;
  }
  h1 {
    font-size: 37px;
    margin-top: 30px;
  }
  .bg-top {
    min-height: 500px;
  }
  .bg-top h3 {
    font-size: 15px;
    padding: 0 40px;
  }
  .topImageDiv {
    margin-left: -35px;
    margin-right: -35px;
    overflow: hidden;
  }
  .topImage {
    margin-bottom: 60px;
    margin-top: 15px;
    margin-left: -10%;
    width: 120%;
  }
  .arrow {
    display: none;
  }
  .searchForm {
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .searchForm .form-inline .form-control {
    width: 100%;
  }
  .searchForm .search-btn {
    width: 100%;
    margin-left: 0;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .searchAdvanceForm .form-group {
    display: block;
    vertical-align: middle;
    margin-bottom: 0;
  }
  .searchAdvanceForm .form-inline .form-control {
    width: 375px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
  }
  .searchAdvanceForm .search-btn {
    width: 55px;
    margin-left: -7px;
  }
  .searchAdvanceForm label {
    display: none;
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    margin-left: 13px;
  }
  .closeAdvance {
    visibility: hidden;
    margin-bottom: -38px;
  }
  .closeAdvance span {
    visibility: initial;
    font-size: 20px;
    margin-right: -5px;
  }
  .textAdvance {
    margin-bottom: 25px;
  }
  .textAdvance span {
    font-size: 23px;
  }
  .advanceSearch {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .advanceSearch .advanceSearchText {
    font-size: 18px;
    font-weight: normal;
  }
  .description {
    font-size: 15px;
    padding: 50px 0;
  }
  .more-info .row {
    margin-top: 0;
    padding-bottom: 0;
    border: none;
  }
  .more-info .info {
    width: 100%;
  }
  .more-info .info .inner {
    padding: 0;
  }
  .more-info .info h3 {
    margin: 0;
    padding: 30px 0;
    border-top: 1px solid #d2d2d2;
    line-height: 1;
    position: relative;
  }
  .more-info .info h3 a {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: auto;
    margin-top: 0;
  }
  .more-info .info h3 a span {
    color: #146b58;
    margin-right: 0;
  }
  .more-info .info:last-child .inner h3 {
    border-bottom: 1px solid #d2d2d2;
  }
  .more-info h3 {
    color: #4c4c4c !important;
    font-size: 16px;
    line-height: 30px;
    margin: 0 -20px 0 -38px;
    padding: 25px 18px;
  }
  .more-info p {
    display: none !important;
  }
  .more-info .min-plus {
    display: none;
  }
  .more-info h3 a {
    display: block;
    font-size: 20px;
    height: 77px;
    margin-top: -30px;
    position: absolute;
    text-align: right;
    text-decoration: none;
    width: 100%;
  }
  .more-info h3 a span {
    margin-right: -8px;
  }
  .more-info .info.info1,
  .more-info .info.info2,
  .more-info .info.info3 {
    padding-left: 0;
    padding-right: 0;
  }
  .filters--head {
    padding: 45px 0;
  }
  .filters--head h3 {
    font-size: 30px;
    text-align: center;
  }
  .share {
    text-align: center !important;
    margin-top: 50px;
  }
  .share > span {
    display: block;
    font-size: 20px;
    color: #4c4c4c;
    margin-bottom: 20px;
  }
  .social-share {
    width: 42px !important;
    height: 42px !important;
  }
  .social-share svg {
    width: 42px !important;
    height: 42px !important;
  }
  .plus span {
    margin-bottom: 32px;
  }
  .inventory {
    position: relative;
    overflow: hidden;
    margin: 0 -20px 10px;
    /*padding: 0 20px;*/
  }
  .inventory .dropdown1,
  .inventory .dropdown2,
  .inventory .dropdown3,
  .inventory .dropdown4 {
    padding: 0;
  }
  .inventory .dropdown {
    width: 100%;
    padding: 0 0 1px 0;
  }
  .filters-container {
    position: relative;
    display: none;
  }
  .inventory h3 {
    padding: 45px 0 40px;
    margin: 0;
    text-align: center;
  }
  .inventory .share {
    text-align: center;
    margin-top: 0;
    margin-bottom: 35px;
  }
  .inventory .share span {
    display: block;
    padding-bottom: 10px;
  }
  .filtersButton,
  .hideFiltersButton {
    background-color: #333;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-size: 18px;
    height: auto;
    /*margin: 0 10px 15px;*/
    padding: 10px 0;
    position: relative;
    text-align: center;
    transition: .3s all ease;
  }
  .filtersButton:hover, .filtersButton:focus, .filtersButton:active,
  .hideFiltersButton:hover,
  .hideFiltersButton:focus,
  .hideFiltersButton:active {
    background: #262626;
  }
  .filtersButton:active:focus,
  .hideFiltersButton:active:focus {
    background: #262626;
  }
  .hideFiltersButton {
    margin: 0;
    padding: 10px 20px;
    text-align: left;
  }
  .hideFiltersButton span {
    font-size: 15px;
  }
  .inventory .filters-xs li.dropdown-submenu,
  .inventory .filters-xs .dropdown-menu > li.dropdown-submenu:focus,
  .inventory .filters-xs .dropdown-menu > li.dropdown-submenu:hover {
    background-color: #ffffff;
    padding: 0;
    color: #4c4c4c;
  }
  .inventory .filters-xs li.dropdown-submenu p {
    padding: 3px 50px 3px 20px;
    margin: 0;
  }
  .inventory .filters-xs li.dropdown-submenu span {
    color: #4c4c4c;
    margin-top: -4px;
  }
  .inventory .filters-xs li.dropdown-submenu.first,
  .inventory .filters-xs li.dropdown-submenu.first:focus,
  .inventory .filters-xs li.dropdown-submenu.first:hover {
    background-color: #4c4c4c;
    color: #ffffff;
  }
  .inventory .filters-xs li.dropdown-submenu.first span {
    color: #ffffff;
  }
  .inventory .filters-xs .dropdown-submenu .dropdown-menu > li:first-child > a {
    box-shadow: inset 0 7px 10px -7px black;
  }
  .noBorder {
    border: none !important;
  }
  .filters-xs .retour {
    background-color: #73726c;
    height: 37px;
    color: #ffffff;
    font-size: 12px;
    padding-top: 8px;
  }
  .filters-xs .retour span {
    color: #ffffff;
    float: left;
    margin-right: 15px;
    margin-top: -12px;
    padding-left: 15px;
  }
  .filters-xs .dropdown.open {
    margin: 0 -20px;
  }
  .filters-xs .dropdown.open .filtersButton {
    display: none;
  }
  .filters-xs .dropdown-menu {
    position: relative;
    margin-top: 0;
    background-color: #e2d1a6;
  }
  .filters-xs .dropdown > .dropdown-menu {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .filters-xs .dropdown-submenu.open > .dropdown-menu > li > a {
    background-color: #ffffff;
    font-size: 11px;
    padding: 0 40px;
    border-bottom: 1px solid #4c4c4c;
    border-top: none;
  }
  .subsection {
    background-color: #e8e8e8;
    color: #4c4c4c;
    font-size: 30px;
    height: 115px;
    padding-top: 35px;
    text-align: center;
    clear: both;
  }
  .masonry-processed:after {
    content: '';
    display: block;
    clear: both;
  }
  .grid-sizer {
    width: 32.5%;
  }
  .grid-item {
    width: 32.5%;
  }
  .gutter-sizer {
    width: 1.25%;
  }
  .object .title div {
    font-size: 9px;
  }
  .contact .contacts-list li {
    display: inline-block;
    width: 45%;
    vertical-align: top;
    margin-top: 30px;
    padding: 0 20px;
  }
  .form-section {
    padding: 30px 0;
  }
  .field-block {
    width: 100%;
  }
  input[type='text'], input[type='email'], input[type='search'],
  input[type='password'], input[type='tel'], textarea {
    width: 100%;
  }
  .form-section h2.top-space {
    margin-top: 30px;
  }
  .form-section label {
    width: 100%;
    text-align: left;
  }
  .form-section .button-container {
    text-align: center;
  }
  .form-section .buttons.submit {
    margin-left: 0;
    margin-top: 20px;
  }
  .form-section .tabs {
    margin-bottom: 30px;
  }
  .form-section .tabs .tab {
    width: 100%;
    border-left: none;
    margin-bottom: 1px;
  }
  /* search page */
  .search-results-title h2 {
    font-size: 20px;
  }
  .container.search-results-form {
    padding-left: 0;
    padding-right: 0;
  }
  .bg-top-arrow {
    margin: 0;
  }
  .bg-top-arrow .arrow {
    display: block;
  }
  footer .plus span {
    font-size: 33px;
    height: 40px;
    line-height: 32px;
    width: 40px;
    margin-top: 20px;
  }
  footer .closeFooter {
    margin-top: 15px;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
  footer .orderDiv {
    margin: 0 -15px;
  }
  .footerPatrimoine .closeFooter {
    margin-top: -12px;
  }
  footer .closeFooter .icon-close {
    font-size: 17px;
  }
  footer .publication {
    font-size: 15px;
    text-align: center;
    padding-top: 25px;
  }
  footer .title {
    font-size: 20px;
    text-align: center;
    padding: 10px 20px;
  }
  footer .description {
    font-size: 13px;
    text-align: center;
    padding: 2px 0 10px;
  }
  footer .order {
    font-size: 12px;
    margin-top: 12px;
    text-align: center;
    width: 100%;
    margin-bottom: 33px;
  }
  footer .img-publication {
    margin: auto;
    padding-bottom: 15px;
    padding-top: 25px;
    width: 210px;
  }
  footer .footer-row {
    margin: 0 -15px;
  }
  .footerPatrimoine .logo {
    margin: 25px 0 10px;
    float: none;
  }
  footer .logo img {
    margin: auto;
    width: 125px;
  }
  footer .footerOff {
    display: block;
  }
  .footerPatrimoine .title {
    font-size: 20px;
    padding: 10px 20px;
  }
  .footerPatrimoine .description {
    font-size: 16px;
  }
  .footerPatrimoine .visit-site {
    font-size: 14px;
    padding: 8px 15px;
    text-align: center;
    margin: 25px auto;
    width: 100%;
  }
  .footerPatrimoine .list {
    font-size: 16px;
    padding: 15px 0 10px;
    text-align: center;
  }
  .footerPatrimoine .list > p {
    padding-left: 10px;
    text-indent: -10px;
    margin-bottom: 15px;
  }
  .footerPatrimoine .logo-FRB {
    display: block;
    margin: 7px auto 45px;
    width: 235px;
  }
  .footerPatrimoine .logo-FRB img {
    height: 60px;
    float: left;
  }
  .footerPatrimoine .plus span {
    background-color: #a6a6a6;
    color: #4c4c4c;
  }
  .footerPatrimoine .plus span:hover {
    background-color: #919090;
  }
  .footerPatrimoine .footerOff .icon-close {
    color: #ffffff;
  }
  .footerPatrimoine .logo.logo--patrimoine {
    margin-right: 0;
  }
  .footerPatrimoine .logo.logo--patrimoine img {
    height: 40px;
    width: auto !important;
  }
  .footerPatrimoine .logo-FRB {
    margin-bottom: 25px;
    text-align: center;
    width: auto;
    margin-top: 3px;
    margin-right: 10px;
  }
  .footerPatrimoine .logo-FRB img {
    float: none;
  }
  .footer {
    margin-bottom: 20px;
    margin-top: 18px;
    font-size: 14px;
  }
  .footer .dash {
    display: none;
  }
  .footer span {
    margin-bottom: 15px;
  }
  .footer a {
    font-weight: bold;
  }
  .footer .footerMostwanted {
    margin-top: 30px;
  }
  .footer .footerMostwanted a {
    font-weight: normal;
  }
  .footer .footerMostwanted .mw {
    display: inline-block;
    padding: 0;
  }
  .footer span {
    display: block;
    padding: 0 20px;
    text-align: center;
  }
  .mobile-text-center {
    text-align: center !important;
  }
  .az-ctnr .az-row {
    display: flex;
    flex-direction: column;
  }
  .az-ctnr .az-row:nth-child(4n+3) .az-column:last-child {
    order: 1;
  }
  .az-ctnr .az-row:nth-child(4n+3) .az-column:first-child {
    order: 2;
  }
  section:not(.block-system) input[type='text'],
  section:not(.block-system) input[type='email'],
  section:not(.block-system) input[type='search'],
  section:not(.block-system) input[type='password'],
  section:not(.block-system) input[type='tel'],
  section:not(.block-system) textarea {
    width: 100%;
  }
  .page-locations footer {
    margin-top: 0;
  }
  .form-select .dk-select-options {
    position: relative;
  }
}

@media (max-width: 480px) {
  .az-column {
    padding-left: 0;
    padding-right: 0;
  }
  .az-element.az-separator {
    margin: 20px 0;
  }
  .region-secondary-header {
    height: 38px;
  }
  .searchAdvanceForm .form-inline .form-control {
    width: 100%;
  }
  .c-block {
    display: none;
  }
  .figure figcaption {
    padding-left: 10px;
    padding-right: 10px;
  }
  .container.blocks {
    padding: 0;
  }
  .container.smaller {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-block {
    /*padding-bottom: 30px;*/
  }
  .content-block .az-text p {
    padding-left: 0;
    padding-right: 0;
  }
  .content-block h2 {
    position: relative;
    text-align: left;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    padding: 30px 75px 30px 20px;
  }
  .content-block h2:after {
    display: block;
    position: absolute;
    content: "\e901";
    top: 50%;
    margin-top: -11px;
    right: 25px;
    color: #146b58;
    font-size: 20px;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .content-block h3 {
    text-align: center;
  }
  .content-block p, .content-block ul, .content-block table {
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  .content-block.left-image .figure,
  .content-block.right-image .figure {
    width: 100%;
  }
  .content-block.left-image .text-content,
  .content-block.right-image .text-content {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .content-block.full-width-image .text-content,
  .content-block.no-image-centered .text-content {
    margin: 20px 0;
  }
  .content-block.where-to-find {
    padding-top: 0;
  }
  .content-block.where-to-find .figure {
    position: relative;
    left: 0;
    top: auto;
    width: 100%;
    float: none;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .content-block.where-to-find .text-content {
    margin-left: 0;
    max-width: 100%;
    float: none;
  }
  .content-block.where-to-find .text-content h2 {
    margin: 0;
  }
  .content-block.where-to-find .text-content .c-block {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0;
  }
  .bg-top-simple {
    padding-left: 0;
    padding-right: 0;
  }
  .bg-top-simple .title-top h1 {
    font-size: 27px;
  }
  .article-details-header {
    padding: 20px;
  }
  .article-details-header .pull-right {
    display: block;
    text-align: center;
    float: none !important;
  }
  .article-details .pictures .figure {
    width: 100%;
  }
  .article-details .pictures .figure:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .article-details .pictures .figure img {
    width: 100%;
    height: auto;
  }
  .article-details .title-spec {
    margin-bottom: 20px;
  }
  .article-details .specs,
  .article-details .desc {
    padding: 30px 0;
  }
  .article-details .specs p,
  .article-details .desc p {
    font-size: 15px;
  }
  .article-details .specs .col-sm-6 {
    padding-right: 0;
  }
  .article-details .specs .r_title {
    display: inline;
    float: none;
    width: auto;
  }
  .article-details .specs .r_spec {
    display: inline;
    float: none;
    width: auto;
  }
  .grid-sizer {
    width: 49%;
  }
  .grid-item {
    width: 49%;
  }
  .gutter-sizer {
    width: 1%;
  }
  /* contact page */
  .contact {
    margin-bottom: 30px;
  }
  .contact .logo-frb {
    display: block;
    max-width: 340px;
    width: 100%;
    height: 137px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 30px auto 0;
    text-indent: -9999em;
  }
  .contact h2 {
    margin-bottom: 0;
    font-size: 21px;
  }
  .contact .contacts-list li {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
    padding: 0 20px;
  }
  .bg-top {
    min-height: 470px;
  }
  .form-item {
    position: relative !important;
    bottom: auto;
  }
  .form-item.form-item-sort-order {
    margin-top: 20px;
    padding-bottom: 35px;
  }
  .form-item.form-item-sort-order .form-select .dk-selected {
    border: none;
    border-top: 1px solid #b5b5b5;
    border-bottom: 1px solid #b5b5b5;
  }
  .form-item .form-select .dk-selected {
    border-right: none;
    border-left: none;
    padding-left: 20px;
  }
  .form-select .dk-option {
    padding-left: 20px;
  }
  .form-select .dk-selected {
    padding-left: 20px;
  }
  .subsection {
    height: auto;
    padding: 20px 0;
  }
  .subsection.subsection--inventory {
    padding-bottom: 5px;
  }
  .inventory {
    padding: 0;
  }
  .filtersButton {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 880px) {
  .bg-top {
    background-position: 50% 60%;
    background-size: 200%;
    padding-top: 15px;
  }
  .slick-slider .slick-dots {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .image-modal-content {
    width: 100%;
  }
}
