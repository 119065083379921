.buttons {
  display: inline-block;
  padding: 9px 25px;
  font-family: $font;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-decoration: none;
  background-color: $color-green-darker;
  border: 0;
  @include hoverCta($color-green-darker);
}


.buttons.email:after {
  display: inline-block;
  content: '';
  width: 18px;
  height: 12px;
  background: url('../images/ico-mail.png') no-repeat;
  margin-left: 20px;
}

.buttons.orange {
  background-color: #fd8f3f;
  color: #ffffff;
}

.buttons.orange:hover {
  background-color: #fda565;
}

.buttons.gray {
  background-color: #4c4c4c;
  color: #ffffff;
}

.buttons.gray:hover {
  background-color: #707070;
}


.plus{
  display: inline-block;
  span{
    background-color: $color-green-darker;
    border-radius: 0;
    color: #ffffff;
    display: inline-block;
    font-family: $font;
    font-weight: 400;
    font-size: 14px;
    height: auto;
    line-height: 1;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 8px 25px;
    width: auto;
    cursor: pointer;
    @include hoverCta($color-green-darker)
  }
}
.min-plus{
  a{
    display: block;
    span{
      background-color: $color-green-darker;
      border-radius: 0;
      color: #ffffff;
      display: inline-block;
      font-family: $font;
      font-weight: 400;
      font-size: 14px;
      height: auto;
      line-height: 1;
      padding: 8px 25px;
      width: auto;
      @include hoverCta($color-green-darker)
    }
  }
}

.more{
  span{
    font-size: 75px;
    line-height: 1;
    color: #ffffff;
  }
}