.region-secondary-header {
  height: auto; /*47px;*/
  position: relative;
  z-index: 2;
}

#back-collection {
  position: absolute;
  display: block;
  float: left;
  margin-left: -20px;
  padding: 14px 0;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $font;
  font-weight: 400;
  z-index: 5;
  transition: all .3s ease;
}

#back-collection.below {
  z-index: 0;
}

#back-collection:hover,
#back-collection:focus {
  text-decoration: none;
  color: $color-green-main;
}