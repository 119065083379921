.at-icon-wrapper {
  // force rounded corner
  border-radius: 100% !important;
  /*width: 34px !important;
  height: 34px !important;
  line-height: 38px !important;
  .at-icon{
    width: 32px !important;
    height: 27px !important;
  }*/
}

.addthis_toolbox {
  margin-left: 5px;
}

.social-share {
  display: inline-block;
  width: 34px;
  height: 34px;
  transition: all .3s ease;
  margin-left: 8px;
  &:hover{
    transform: translateY(-5px);
  }
  &.social-share--tw {
    background: url("../images/share/share_tw.svg") no-repeat 0 0;
  }
  &.social-share--fb {
    background: url("../images/share/share_fb.svg") no-repeat 0 0;
  }
  &.social-share--mail {
    background: url("../images/share/share_mail.svg") no-repeat 0 0;
  }
  &.social-share--lk {
    background: url("../images/share/share_lk.svg") no-repeat 0 0;
  }
}