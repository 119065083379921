// cta
@mixin hoverCta($orig, $default: 5%){
  transition: .3s all ease;
  &:hover,
  &:focus,
  &:active{
    background: darken($orig, $default);
  }
  &:active{
    &:focus{
      background: darken($orig, $default);
    }
  }
}


@mixin ellipsis($font-size, $line-height, $lines-to-show) {
  display: block;
  display: -webkit-box;
  height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis; 
}