section:not(.block-system) input[type='text'],
section:not(.block-system) input[type='email'],
section:not(.block-system) input[type='search'],
section:not(.block-system) input[type='password'],
section:not(.block-system) input[type='tel'],
section:not(.block-system) textarea {
  width: 500px;
  padding: 10px 15px;
  margin-bottom: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #4c4c4c;
  -webkit-user-select: text;
  font-family: $font;
  font-weight: 400;
}

section:not(.block-system) input[type='text'].bordered,
section:not(.block-system) input[type='email'].bordered,
section:not(.block-system) input[type='search'].bordered,
section:not(.block-system) input[type='password'].bordered,
section:not(.block-system) input[type='tel'].bordered,
section:not(.block-system) textarea.bordered {
  border: 1px solid $color-green-main;
}

section:not(.block-system) input[type='text'].error,
section:not(.block-system) input[type='email'].error,
section:not(.block-system) input[type='search'].error,
section:not(.block-system) input[type='password'].error,
section:not(.block-system) input[type='tel'].error,
section:not(.block-system) textarea.error {
  color: #e73131;
  font-family: $font;
  font-weight: 700;
}

input[type='text'].error.bordered, input[type='email'].error.bordered, input[type='search'].error.bordered,
input[type='password'].error.bordered, input[type='tel'].error.bordered, textarea.error.bordered {
  border: 1px solid #e73131;
}

section:not(.block-system) input[type="file"] {
  border: 1px solid;
}

section:not(.block-system) label {
  font-family: $font;
  font-weight: 700;
  color: #4c4c4c;
  font-size: 14px;
  padding-right: 20px;
}

section:not(.block-system) textarea {
  resize: none;
  height: 140px;
  margin-bottom: 0;
  vertical-align: top;
}

section:not(.block-system) select {
  padding: 9px;
  border: 1px solid $color-green-main;
}

select.error {
  border: 1px solid #e73131;
}

.field-block {
  float: left;
  margin-bottom: 10px;
}

.form-error-message {
  display: none;
  padding: 10px 15px;
  background-color: #e73131;
  color: #ffffff;
}

fieldset > legend {
  display: none;
}

section.confirmation {
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  text-align: center;
}