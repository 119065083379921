.filters {
  margin-left: 6px;
}

.filters > div {
  padding-right: 9px;
  padding-left: 20px;
}

.filters > div:first-child {
  padding-right: 0;
  padding-left: 10px;
}

.filters label {
  font-family: $font;
  font-weight: 700;
  color: #4c4c4c;
  font-size: 12px;
  padding-right: 20px;
  float: left;
}

.filters input {
  border: none;
  background-color: transparent;
  border-bottom: solid 1px #b7a77f;
  height: 15px;
  width: 100%;
  border-radius: 0;
}

.filters .filtersDiv {
  overflow: hidden;
  padding-right: 6px;

  // test
  display: none;
}

.inventory {
  margin-bottom: 10px;
  margin-right: 1px;
  background: #e8e8e8;
  padding: 15px 10px;
}

.inventory .dropdown {
  padding: 0;
  width: 185px;
  display: inline-block;
  .description{
    display: none;
  }
}

.inventory .dropdown1 {
  /*padding-left: 14px;
  padding-right: 9px;*/
}

.inventory .dropdown2 {
  padding-left: 3px;
  padding-right: 6px;
}

.inventory .dropdown3 {
  padding-left: 6px;
  padding-right: 3px;
}

.inventory .dropdown4 {
  /*padding-left: 9px;
  padding-right: 14px;
  float: right;*/
  float: none;
  padding: 0;
  .form-item{
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.object:hover:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 10px solid $color-green-darker;
  border-bottom: 50px solid $color-green-darker;
  z-index: 1;
}

.object a {
  position: relative;
  text-decoration: none;
}

.object img {
  height: auto;
  width: 237px;
  border: solid 1px rgba(186, 182, 173, 0.5);
  border-bottom: none;
}

.object .title {
  border: solid 1px rgba(186, 182, 173, 0.5);
  border-top: none;
  width: 237.5px;
  height: 50px;
  display: table;
  align-items: center;
  z-index: 2;
  position: relative;
}

.object .title div {
  margin: auto;
  padding: 10px 20px;
  text-align: center;
  font-family: $font;
  font-weight: 700;
  font-size: 12px;
  color: #4c4c4c;
  display: table-cell;
  vertical-align: middle;

}

.object{
  &:hover{
    .title{
      border: 1px solid transparent;
    }
  }
}

.object:hover .title div {
  background-color: $color-green-darker;
}

.object .title div span a {
  color: #4c4c4c;
}

.object:hover .title div span a {
  color: white;
}

.inventory-objects {
  padding: 0;
}

.inventory-objects .container {
  overflow: hidden;
}

.inventory-objects-content {
  overflow: hidden;
}

.masonry-processed {
  max-width: 1000px;
}

.masonry-processed:after {
  content: '';
  display: block;
  clear: both;
}

.grid-item {
  background-color: white;
  border-style: none;
  width: 238px;
  float: left;
  margin: 0 0 10px;
  padding: 0;
}
#inventory-top {
  position: relative;
  top: -48px;
}

.captionright {
  text-align: right;
  font-size: 0.8em
}

.inventory-item {
  cursor: pointer;
  transition: 0.3s;
}

.inventory-item:hover {
  opacity: 0.8;
}

.filters--head{
  padding: 65px 0;
  h3{
    font-size: 40px;
    margin: 0;
  }
}

.filters-container{
  display: flex;
  justify-content: space-between;
}